import queryWithToken, {
    create,
    deleteById,
    findById,
    updateById,
    updateOrCreate,
} from "./index";
import { queryCache, useMutation, useQuery } from "react-query";
import { get } from "lodash-es";
import { toast } from "react-toastify";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "recurrent_absences_aggregations";
//

const mutateDefaultOptions = {
    throwOnError: true,
    // When mutate is called:
    onMutate: () => {
        queryCache.cancelQueries(ressourceKey);
    },
};

export function useQueryRecurrentAbsencesAggregationById(id) {
    return useQuery(id && [ressourceKey, id], findById, {
        refetchOnWindowFocus: false,
    });
}

export function useQueryRecurrentAbsencesAggregations(filters) {
    const { data, ...rest } = useQuery(
        [ressourceKey, filters],
        () =>
            queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
                method: "GET",
                params: {
                    page: null,
                    pagination: false,
                    ...filters,
                },
            }),
        {
            refetchOnWindowFocus: false,
        }
    );
    return {
        data: get(data, "hydra:member"),
        ...rest,
    };
}

export function useUpdateRecurrentAbsencesAggregation() {
    return useMutation(updateById.bind(null, ressourceKey), {
        onSuccess: () => {
            queryCache.refetchQueries([ressourceKey]);
            queryCache.refetchQueries("history");
        },
    });
}

export function useCreateRecurrentAbsencesAggregation() {
    return useMutation(create.bind(null, ressourceKey), {
        throwOnError: true,
        onMutate: (newAbsence = null) => {
            queryCache.cancelQueries(ressourceKey);
        },
        onSuccess: (data) => {
            queryCache.setQueryData([ressourceKey, data.id], data);
            queryCache.refetchQueries(ressourceKey);
            queryCache.refetchQueries("history");
            toast.success("La récurrence d'absence a bien été créée");
        },
    });
}

export function useCreateOrUpdateRecurrentAbsencesAggregation() {
    return useMutation(updateOrCreate.bind(null, ressourceKey), {
        ...mutateDefaultOptions,
        onSuccess: (data) => {
            queryCache.setQueryData([ressourceKey, data.id], data);
            queryCache.refetchQueries(ressourceKey);
            queryCache.refetchQueries("history");
        },
    });
}

export function useDeleteRecurrentAbsencesAggregation(resetHistory = false) {
    return useMutation(deleteById.bind(null, ressourceKey), {
        onSuccess: () => {
            queryCache.refetchQueries(ressourceKey);
            if (resetHistory) {
                queryCache.refetchQueries("history");
            }
        },
        onError: () => {
            toast.error("Erreur lors de la suppresion");
        },
    });
}

