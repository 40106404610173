import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useQueryProcess, useQueryProcessById } from "../api/process";
import ProcessList from "../components/Process/ProcessList";
import { get } from "lodash-es";
import { PermissionChecker } from "../contexts/permissions";
import Spinner from "../components/Spinner";
import ProcessDetail from "../components/Process/ProcessDetail";
import { useDesktop } from "../hooks/useDesktop";
import { HeaderBar } from "../components/HeaderBar";
import ProcessForm from "../forms/ProcessForm";

function ProcessDetailPage({ processId }) {
  const isDesktop = useDesktop();
  const { data: process } = useQueryProcessById(processId);
  return (
    <div>
      <Helmet>
        <title>{get(process, "label", "")}</title>
      </Helmet>
      <HeaderBar>{get(process, "label", "Détail processus")}</HeaderBar>
      <div
        className={"xl:w-8/12 mx-auto px-0 sm:px-8 md:px-24 xl:px-0 "}
        style={isDesktop ? { maxWidth: "900px" } : {}}
      >
        <div className="px-8 mt-8 mb-4">
          <ProcessDetail process={process} />
        </div>
      </div>
    </div>
  );
}
function ProcessListPage({ isOut }) {
  const isDesktop = useDesktop();
  const { data: process } = useQueryProcess({
    isOut,
    "order[label]": "asc",
  });
  return (
    <div>
      <Helmet>
        <title>Processus {isOut ? "de départ" : "d'arrivée"}</title>
      </Helmet>
      <HeaderBar>Processus {isOut ? "de départ" : "d'arrivée"}</HeaderBar>

      <div
        className={"xl:w-8/12 mx-auto px-0 sm:px-8 md:px-24 xl:px-0 "}
        style={isDesktop ? { maxWidth: "900px" } : {}}
      >
        <div className="mt-8 mb-4">
          <PermissionChecker
            permissions={[
              "kdix.actions.process_in_out.edit",
              "kdix.actions.process_in_out.edit.agency",
              "kdix.actions.process_in_out.edit.department",
              "kdix.actions.process_in_out.edit.service",
              "kdix.actions.process_in_out.edit.own",
            ]}
          >
            <ProcessForm isOut={isOut} />
          </PermissionChecker>
          <ProcessList process={process} />
        </div>
      </div>
    </div>
  );
}

export default function Process({ isOut }) {
  const { id } = useParams();
  return (
    <React.Suspense fallback={<Spinner />}>
      {id ? (
        <PermissionChecker
          permissions={[
            "kdix.actions.process_in_out.view",
            "kdix.actions.process_in_out.view.agency",
            "kdix.actions.process_in_out.view.department",
            "kdix.actions.process_in_out.view.service",
            "kdix.actions.process_in_out.view.own",
          ]}
        >
          <ProcessDetailPage processId={id} />
        </PermissionChecker>
      ) : (
        <ProcessListPage isOut={isOut} />
      )}
    </React.Suspense>
  );
}
