import Block from "../Block";
import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import { useQueryRequests } from "../../api/requests";
import { flatMap, get, map, size } from "lodash-es";

function RequestsBlock({ userIri }) {
  const now = new Date();
  now.setHours(23, 59, 59, 999);
  const filter = { approved: false, "reminderAt[before]": now };
  const { data } = useQueryRequests(filter);
  const requests = flatMap(data, (page) => page["hydra:member"]);
  const nbRequests = size(requests);
  const nbNewRequests = size(
    requests.filter((r) => {
      const consulted = map(get(r, "consultedBy"), "@id");
      return !consulted.includes(userIri);
    })
  );
  return (
    <>
      <Block className="text-center flex-grow bg-white">
        <Link
          to={`/demandes`}
          className="flex flex-col flex-auto items-center justify-between flex-grow h-full"
        >
          <div
            className={`bg-green-200 inline-flex text-white text-3xl rounded-full font-bold w-16 h-16 items-center justify-center`}
          >
            {nbRequests}
          </div>
          {nbNewRequests ? (
            <span
              className="unread bg-red-700 rounded-full text-white text-xs absolute w-5 h-5 ml-1 -mt-3"
              style={{ marginLeft: "43px", marginTop: "5px" }}
            >
              &nbsp;
            </span>
          ) : null}
          <div className="mt-3 text-xl font-black">
            {`Demande${nbNewRequests > 1 ? "s" : ""}`}
          </div>
          <div className="mt-2 text-base font-light">
            {`dont ${nbNewRequests} nouvelle${nbNewRequests > 1 ? "s" : ""}`}
          </div>
          <Button
            className={`font-bold p-1 focus:outline-none text-white w-full block`}
          >
            voir tout
          </Button>
        </Link>
      </Block>
    </>
  );
}

export default RequestsBlock;
