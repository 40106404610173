import Block from "../Block";
import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import { size } from "lodash-es";
import {useQueryLokoaRequests} from "../../api/lokoaRequests";

function LokoaRequestsBlock({ collaboratorId, domainId }) {
  const noAnswersFilter = { domain: domainId, createdBy: collaboratorId, 'exists[lokoaRequestAnswers]': false };
  const unseenAnswersFilter = { domain: domainId, createdBy: collaboratorId, 'exists[lokoaRequestAnswers]': true, 'lokoaRequestAnswers.viewed': false };

  const { data: requestsWithoutAnswers } = useQueryLokoaRequests(noAnswersFilter);
  const { data: requestsWithUnseenAnswers } = useQueryLokoaRequests(unseenAnswersFilter);

  const nbRequestsWithoutAnswers = size(requestsWithoutAnswers);
  const nbRequestsWithUnseenAnswers = size(requestsWithUnseenAnswers);

  return (
      <>
        <Block className="text-center flex-grow bg-white">
          <Link
              to={`/demandes-chantier`}
              className="flex flex-col flex-auto items-center justify-between flex-grow h-full"
          >
            <div
                className={`bg-green-400 inline-flex text-white text-3xl rounded-full font-bold w-16 h-16 items-center justify-center`}
            >
              {nbRequestsWithoutAnswers}
            </div>
            {nbRequestsWithUnseenAnswers ? (
                <span
                    className="unread bg-red-700 rounded-full text-white text-xs absolute w-5 h-5 ml-1 -mt-3"
                    style={{ marginLeft: "43px", marginTop: "5px" }}
                >
              &nbsp;
            </span>
            ) : null}
            <div className="mt-3 text-xl font-black">
              {`Demande${nbRequestsWithoutAnswers > 1 ? "s" : ""} chantier`}
            </div>
            <div className="mt-2 text-base font-light">
              {`${nbRequestsWithUnseenAnswers} nouvelle${nbRequestsWithUnseenAnswers > 1 ? "s" : ""} réponse${nbRequestsWithUnseenAnswers > 1 ? "s" : ""}`}
            </div>
            <Button
                className={`font-bold p-1 focus:outline-none text-white w-full block`}
            >
              voir le détail
            </Button>
          </Link>
        </Block>
      </>
  );
}

export default LokoaRequestsBlock;
