import {
  AbsencesStats,
  DeparturesStats,
  DirectionMessagesStats,
  EventsStats,
  HiringStats,
  MessagesStats,
  OvertimeStats,
  RequestsStatsByType,
  WorkAccidentStats,
} from "../components/StatsBlock";

import { Helmet } from "react-helmet";
import React from "react";
import { WeatherCompany } from "../components/Weather";
import {
  PermissionChecker,
  useDomain,
  useIsModuleActive,
  usePermissionsChecker,
} from "../contexts/permissions";
import { useQueryStatisticsByDomainId } from "../api/statistics";
import { HeaderBar } from "../components/HeaderBar";

function Statistics() {
  const domainId = useDomain();
  const { data } = useQueryStatisticsByDomainId(domainId);
  const canViewAbsence = useIsModuleActive("absences");
  const canViewOvertime = useIsModuleActive("overtime");
  const canViewWorkAccident = useIsModuleActive("prevention");
  const canEditDirectorWord = usePermissionsChecker({
    permissions: ["kdix.actions.home_message.edit"],
  });

  React.useEffect(() => {
          var _paq = window._paq = window._paq || [];
          _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
      
          var u="//matomo.kdix.pockost.com/";
          _paq.push(['setTrackerUrl', u+'matomo.php']);
          _paq.push(['setSiteId', '1']);
          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);

    }, [])

  return (
    <div>
      <Helmet>
        <title>Statistiques</title>
      </Helmet>
      <HeaderBar>Statistiques collaborateurs actifs et désactivés</HeaderBar>
      <div className="p-8 pb-24">
        <div>
          <WeatherCompany
            moods={data.moodByTypesThisYear}
            previousMoods={data.moodByTypesLastYear}
          />
        </div>
        <div>
          <RequestsStatsByType
            currentYear={data.requestsThisYear}
            previousYear={data.requestsLastYear}
          />
          <div className={`grid ${canViewOvertime && canViewWorkAccident? "lg:grid-cols-4" : !canViewOvertime && !canViewWorkAccident? "lg:grid-cols-2" : "lg:grid-cols-3"} lg:gap-3 mb-4 xl:mb-3`}>
          <DirectionMessagesStats
            currentYear={data.numberOfHomeMessageThisYear}
            previousYear={data.numberOfHomeMessageLastYear}
            link={canEditDirectorWord ? "/parametres/mot-dirigeant" : null}
            linkLabel={"voir tous les mots du dirigeant"}
          />
          <EventsStats
            currentYear={data.eventsThisYear}
            previousYear={data.eventsLastYear}
          />
          {canViewOvertime && (
            <OvertimeStats
              currentYear={data.numberOfOvertimeThisYear}
              previousYear={data.numberOfOvertimeLastYear}
              label="dans l'entreprise depuis un an"
            />
          )}
          {canViewWorkAccident && (
            <WorkAccidentStats
              currentYear={data.numberOfWorkAccidentThisYear}
              previousYear={data.numberOfWorkAccidentLastYear}
            />
          )}
          </div>
          <div className={`grid lg:grid-cols-2 lg:gap-3 mb-4 xl:mb-3`}>
          <HiringStats
            currentYear={data.hiredThisYear}
            previousYear={data.hiredLastYear}
          />
          <DeparturesStats
            currentYear={data.departureThisYear}
            previousYear={data.departureLastYear}
          />
          </div>
          
          <PermissionChecker
            permissions={[
              "kdix.actions.message.view",
              "kdix.actions.message.view.agency",
              "kdix.actions.message.view.department",
              "kdix.actions.message.view.service",
              "kdix.actions.message.view.own",
            ]}
          >
            <MessagesStats
              currentYear={data.numberOfMessagesByTypeThisYear}
              previousYear={data.numberOfMessagesByTypeLastYear}
              linkLabel="voir tout les messages"
              title="Messages"
            />
          </PermissionChecker>
          {canViewAbsence && (
            <AbsencesStats
              currentYear={data.absenceByTypeThisYear}
              previousYear={data.absenceByTypeLastYear}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Statistics;
