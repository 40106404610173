import React, { useLayoutEffect, useRef } from "react";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import { filter, get, isEmpty, map, each, trim } from "lodash-es";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Input from "../components/Input";
import Checkbox from "../components/Checkbox";
import Alert from "../components/Alert";
import Button from "../components/Button";
import EditButton from "../components/HistoryItem/EditButton";
import {
  useCreateOrUpdateTypeAbsence,
  useDeleteTypeAbsence,
} from "../api/typeAbsences";
import { ToggleField } from "../components/Toogle";
import Select from "../components/Select";
import { useQueryTypeContractCategories } from "../api/typeContractCategory";
import { slugTimeManagement } from "./formsUtils/slugTimeManagement";
import {useQueryAgencies} from "../api/agencies";

const schema = Yup.object().shape({
  label: Yup.string()
    .max(50, "Le nom doit faire moins de 50 caractères")
    .test({
      test: (value) => trim(value) !== "",
      message: "Requis",
    })
    .required("Requis"),
  thoughtfulnessDelay: Yup.number().max(365, "Maximum 365 jours"),
  // timeManagements: Yup.array().of(
  //   Yup.object().shape({
  //     slug: Yup.object().shape({
  //       value: Yup.string().required("Ce champ est requis"),
  //     }),
  //   })
  // ),
});

const defaultSelectValue = {
  label: "",
  value: "",
};

export default function TypeAbsenceForm({ typeAbsence = null }) {
  const [
    updateTypeAbsence,
    { error: errorUpdate },
  ] = useCreateOrUpdateTypeAbsence();
  const [isEditing, setIsEditing] = React.useState(typeAbsence === null);
  const readOnly = !!typeAbsence && !isEditing;
  const [deleteTypeAbsence] = useDeleteTypeAbsence();
  const { data: category } = useQueryTypeContractCategories();
  const { data: agency } = useQueryAgencies();
  const [hasTimeManagement, setHasTimeManagement] = React.useState(
    !isEmpty(get(typeAbsence, "timeManagements", []))
  );

  return (
    <div>
      <Formik
        initialValues={{
          label: get(typeAbsence, "label", ""),
          workAccidentType: get(typeAbsence, "workAccidentType", false),
          rubricCode: get(typeAbsence, "rubricCode", ""),
          needValidation: get(typeAbsence, "needValidation", false),
          thoughtfulnessDelay: get(typeAbsence, "thoughtfulnessDelay", 0),
          validatorDelay: get(typeAbsence, "validatorDelay", 1),
          prevalidatorDelay: get(typeAbsence, "prevalidatorDelay", 1),
          needPreValidation: get(typeAbsence, "needPreValidation", false),
          needCommentValidation: get(
            typeAbsence,
            "needCommentValidation",
            false
          ),
          needCommentPreValidation: get(
            typeAbsence,
            "needCommentPreValidation",
            false
          ),
          recurrent: get(typeAbsence, "recurrent", false),
          needCertificate: get(typeAbsence, "needCertificate", false),
          needMotive: get(typeAbsence, "needMotive", false),
          checkRemainsHolidays: get(typeAbsence, "checkRemainsHolidays", false),
          noPlanning: get(typeAbsence, "noPlanning", false),
          timeManagements: map(
            get(typeAbsence, "timeManagements", []),
            (time) => {
                return ({
                    id: get(time, "id"),
                    category: {
                        label: get(time, "category.label", defaultSelectValue.label),
                        value: get(time, "category[@id]", defaultSelectValue.value),
                    },
                    agency: {
                        label: get(time, "agency.label", defaultSelectValue.label),
                        value: get(time, "agency[@id]", defaultSelectValue.value),
                    },
                    slug: time.slug
                        ? { label: slugTimeManagement[time.slug], value: time.slug }
                        : defaultSelectValue,
                })
            }
          ),
        }}
        enableReinitialize
        validationSchema={schema}
        onSubmit={async (
          { rubricCode, timeManagements, ...values },
          actions
        ) => {
          try {
            //On ne peut pas avoir deux encarts avec même catégorie et même sous catégorie
            //On ne peut pas avoir deux encarts avec une même catégorie et sans sous catégorie
            let hasError = false;
            each(timeManagements, ({ category, ...time }, idx) => {
              if (
                time.slug === "" ||
                (Array.isArray(time.slug) && time.slug.length === 0)
              ) {
                hasError = true;
                actions.setFieldTouched(
                  `timeManagements[${idx}].slug`,
                  true,
                  false
                );
                actions.setFieldError(
                  `timeManagements[${idx}].slug`,
                  "Au moins un des champs est requis"
                );
              }
              const sameCats = filter(
                timeManagements,
                (time) => time.category.value === category.value
              );
              if (category.value === "" && timeManagements.length > 1) {
                actions.setFieldTouched(
                  `timeManagements[${idx}].category`,
                  true,
                  false
                );
                actions.setFieldError(
                  `timeManagements[${idx}].category`,
                  "Ce champ est requis"
                );
              }
              if (sameCats.length > 1) {
                //On a au moins deux éléments sur la même catégorie
                //On doit donc forcément avoir des catégories de défini et une unicité sur celles-ci
                if (category.value === "") {
                  actions.setFieldTouched(
                    `timeManagements[${idx}].category`,
                    true,
                    false
                  );
                  actions.setFieldError(
                    `timeManagements[${idx}].category`,
                    "Ce champ est requis"
                  );
                }
              }
            });
            if (hasError) {
              return;
            }

            await updateTypeAbsence({
              id: get(typeAbsence, "id"),
              data: {
                ...values,
                rubricCode: rubricCode === "" ? null : rubricCode,
                timeManagements: map(
                  timeManagements,
                  ({ id, category, agency, slug, ...time }) => ({
                    category: category.value ? category.value : null,
                    agency: agency.value ? agency.value : null,
                    slug: slug?.value,
                    ...time,
                  })
                ),
              },
            });
            toast.success("Le type d'absence a bien été enregistré");
            actions.resetForm();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values, setFieldValue, resetForm }) => {
          return (
            <Form>
              <div className="mb-4 xl:grid xl:grid-cols-3 gap-12">
                <div className="px-4">
                  <Input
                    readOnly={readOnly}
                    type="text"
                    name="label"
                    label="Nom"
                  />
                  <Input
                    readOnly={readOnly}
                    type="text"
                    name="rubricCode"
                    label="Code Rubrique"
                  />
                  <Checkbox
                    readOnly={readOnly}
                    type="checkbox"
                    name="workAccidentType"
                    label="Absence liée à un accident du travail"
                  />
                  <Checkbox
                    readOnly={readOnly}
                    type="checkbox"
                    name="needCertificate"
                    label="Justificatif obligatoire"
                  />
                  <Checkbox
                    readOnly={readOnly}
                    type="checkbox"
                    name="needMotive"
                    label="Motif du demandeur"
                  />
                  <Input
                    readOnly={readOnly || !values.needValidation}
                    type="number"
                    className="mt-2"
                    name="thoughtfulnessDelay"
                    label="Délai de prévenance"
                  />
                  <Checkbox
                    readOnly={readOnly}
                    type="checkbox"
                    name="checkRemainsHolidays"
                    label="Contrôle par rapport au solde de congés"
                  />
                  <Checkbox
                      readOnly={readOnly}
                      type="checkbox"
                      name="noPlanning"
                      label="Ne pas afficher sur le planning"
                  />
                </div>
                <div className="px-4">
                  <ToggleField
                    name="needPreValidation"
                    label="Demande avec pré-validation"
                    className={"mb-1"}
                    readOnly={readOnly}
                  />
                  <p className="text-sm">
                    Si pas activé, la demande n'aura pas de pré-validation
                  </p>
                  {values.needPreValidation && (
                    <div className="mt-4 bg-gray-50 p-2 border-l-4 border-green-500">
                      <Input
                        readOnly={readOnly}
                        type="number"
                        name="prevalidatorDelay"
                        label="Délai de non-réponse"
                      />
                      <Checkbox
                        readOnly={readOnly}
                        type="checkbox"
                        name="needCommentPreValidation"
                        label="Commentaire obligatoire"
                      />
                    </div>
                  )}
                  <ToggleField
                    name="needValidation"
                    label="Demande avec validation"
                    className={"mb-1 mt-6"}
                    readOnly={readOnly || !!values?.needPreValidation}
                  />
                  <ToggleValidation readOnly={readOnly} />
                  <p className="text-sm">
                    Si pas activé, la demande n'aura pas de validation
                  </p>

                  {values.needValidation && (
                    <div className="mt-4 bg-gray-50 py-2 px-4 border-l-4 border-green-500 ">
                      <Input
                        readOnly={readOnly}
                        type="number"
                        name="validatorDelay"
                        label="Délai de non-réponse"
                      />
                      <Checkbox
                        readOnly={readOnly}
                        type="checkbox"
                        name="needCommentValidation"
                        label="Commentaire obligatoire"
                      />
                    </div>
                  )}
                </div>
                <div className="px-4">
                  <div className="font-bold text-lg leading-tight mb-4">
                    Restrictions
                  </div>
                  {isEmpty(values.timeManagements) && (
                    <div className="mb-4 text-center">Aucune restriction</div>
                  )}
                  <div>
                    <FieldArray name="timeManagements">
                      {({ insert, remove }) => (
                        <>
                          {map(
                            values.timeManagements,
                            (timeManagement, index) => (
                              <div
                                key={index}
                                className="mb-4 bg-gray-50 py-2 px-4 border-l-4 border-green-500 "
                              >
                                <div
                                  className={`w-full ${
                                    isEditing ? "flex" : "hidden"
                                  }`}
                                >
                                  <span
                                    className={"ml-auto cursor-pointer"}
                                    onClick={() => {
                                      remove(index);
                                      setHasTimeManagement(false);
                                    }}
                                  >
                                    X
                                  </span>
                                </div>
                                <Select
                                  isClearable
                                  name={`timeManagements[${index}].category`}
                                  readOnly={readOnly}
                                  isDisabled={readOnly}
                                  label="Catégorie"
                                  options={map(category, (category) => ({
                                    label: category.label,
                                    value: category["@id"],
                                  }))}
                                  value={
                                    values.timeManagements[index].category.value
                                      ? values.timeManagements[index].category
                                      : null
                                  }
                                  onChange={(value, { action }) => {}}
                                />
                                <Select
                                    isClearable
                                    name={`timeManagements[${index}].agency`}
                                    readOnly={readOnly}
                                    isDisabled={readOnly}
                                    label="Site"
                                    options={map(agency, (agency) => ({
                                        label: agency.label,
                                        value: agency["@id"],
                                    }))}
                                    value={
                                        values.timeManagements[index]?.agency?.value
                                            ? values.timeManagements[index].agency
                                            : null
                                    }
                                    placeholder="Tous les sites"
                                    onChange={(value, { action }) => {}}
                                />
                                <div
                                  role="group"
                                  aria-labelledby="checkbox-group"
                                >
                                  <Select
                                    isClearable
                                    name={`timeManagements[${index}].slug`}
                                    readOnly={readOnly}
                                    isDisabled={readOnly}
                                    label="Durée minimum de l'absence"
                                    options={map(
                                      slugTimeManagement,
                                      (label, id) => ({
                                        label: label,
                                        value: id,
                                      })
                                    )}
                                  />
                                </div>
                              </div>
                            )
                          )}

                          <div>
                            {!hasTimeManagement ? (
                              <Button
                                className={`btn--sm w-full ${
                                  isEditing ? "" : "hidden"
                                }`}
                                type="button"
                                onClick={() => {
                                  const newIndex =
                                    values.timeManagements.length;
                                  insert(newIndex, {
                                    category: defaultSelectValue,
                                    agency: defaultSelectValue,
                                    slug: "",
                                  });
                                  setHasTimeManagement(true);
                                }}
                              >
                                Ajouter des restrictions
                              </Button>
                            ) : null}
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </div>
                </div>
              </div>
              {errorUpdate ? (
                <Alert
                  canBeHidden
                  type="error"
                  message={errorUpdate.title}
                  details={errorUpdate.description}
                />
              ) : null}

              {typeAbsence ? (
                <div className="flex items-center">
                  <EditButton
                    onDelete={async () => {
                      try {
                        await deleteTypeAbsence(typeAbsence.id);
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                    isSubmitting={isSubmitting}
                    isEditing={isEditing}
                    onCancel={() => {
                      resetForm();
                      setIsEditing(!isEditing);
                    }}
                    onClick={() => {
                      setIsEditing(!isEditing);
                    }}
                  />
                </div>
              ) : (
                <div className="text-center">
                  <Button
                    className={`btn mt-6 inline-block`}
                    isSubmitting={isSubmitting}
                    isForm={true}
                    type="submit"
                    textLabel="Créer"
                  />
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

function ToggleValidation() {
  const { values, setFieldValue } = useFormikContext();
  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (values?.needPreValidation) {
      setFieldValue("needValidation", true);
    } else {
      setFieldValue("needValidation", false);
    }
  }, [values.needPreValidation, setFieldValue]);
  return null;
}
