import React, { Suspense } from "react";

import { ApolloProvider } from "@apollo/react-hooks";
import Error from "./components/Error";
import ErrorBoundary from "./components/ErrorBoundary";
import Header from "./components/Header";
import { Helmet } from "react-helmet";
import MainRouter from "./Router";
import { PermissionsrProvider } from "./contexts/permissions";
import PrivateRoute from "./components/PrivateRoute";
import ReactModal from "react-modal";
import { ReactQueryConfigProvider } from "react-query";
import { ReactQueryDevtools } from "react-query-devtools";
import Request from "./components/Request";
import { Router } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Spinner from "./components/Spinner";
import { ToastContainer } from "react-toastify";
import client from "./graphql";
import history from "./history";
import useAutomaticLogout from "./hooks/useAutomaticLogout";
import { useDesktop } from "./hooks/useDesktop";
import { useToggle } from "react-use";
import fr from "date-fns/locale/fr";
import { registerLocale, setDefaultLocale } from "react-datepicker";
registerLocale("fr", fr);
setDefaultLocale("fr");

ReactModal.setAppElement("#root");

const queryConfig = { suspense: true, useErrorBoundary: false };

export default function App() {
  useAutomaticLogout();
  const isDesktop = useDesktop();
  const [isOpen, toggleMenu] = useToggle(isDesktop);

    
  return (
    <>
      <ErrorBoundary
        fallback={(error) => {
          return <Error title="Erreur" details={error} />;
        }}
      >
        <ReactQueryConfigProvider config={queryConfig}>
          <ReactQueryDevtools initialIsOpen={false} />

          <Helmet>
            <title>K-dix</title>
          </Helmet>
          <ApolloProvider client={client}>
            <PermissionsrProvider>
              <Router history={history}>
                <ScrollToTop />
                <Header isOpen={isOpen} toggleMenu={toggleMenu} />
                <ErrorBoundary
                  fallback={(error) => {
                    return (
                      <Error
                        title="Erreur lors du chargement de la route"
                        details={error}
                      />
                    );
                  }}
                >
                  <main
                    id="main-content"
                    className={`relative min-h-fullWithoutMenuHeight ${
                      isDesktop &&
                      isOpen &&
                      history.location.pathname.indexOf("/login") !== 0
                        ? "ml-sidebar-open"
                        : isDesktop
                        ? "ml-sidebar"
                        : ""
                    } bg-gray-50`}
                  >
                    <Suspense fallback={<Spinner />}>
                      <MainRouter />

                      <PrivateRoute path="/" exact>
                        <Request isSidebarOpen={isOpen} isDesktop={isDesktop} />
                      </PrivateRoute>
                    </Suspense>
                  </main>
                </ErrorBoundary>
              </Router>
            </PermissionsrProvider>
          </ApolloProvider>
        </ReactQueryConfigProvider>
        <ToastContainer />
      </ErrorBoundary>
    </>
  );
}
