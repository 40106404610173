import Block from "../Block";
import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import { size } from "lodash-es";
import { useQueryMessages, useQueryMessagesReceived } from "../../api/messages";
import findIndex from "lodash-es/findIndex";

function MessagesBlock({
  toValidate = false,
  isPersonal = true,
  userIri,
  userId,
}) {
  return isPersonal ? (
    <MessagePersonal userIri={userIri} userId={userId} />
  ) : (
    <MessageToValidate userIri={userIri} />
  );
}

function MessagePersonal({ userId, userIri }) {
  const dt = new Date();
  dt.setHours(0, 0, 0, 0);
  dt.setDate(dt.getDate() - 6);
  const { data: messages } = useQueryMessagesReceived(
    {
      approved: true,
      sent: true,
      "order[createdAt]": "desc",
      "dateApproved[after]": dt,
      user_id: userId,
    },
    "messages_received"
  );
  const nbMessages =  messages.filter(function (mes) {
    return findIndex(mes["readBy"], { "@id": userIri }) === -1;
  }).length;
  const nbNewMessages = messages.filter(function (mes) {
    return findIndex(mes["readBy"], { "@id": userIri }) === -1;
  }).length;
  return (
    <MessageBlockContainer
      nbNewMessages={nbNewMessages}
      nbMessages={nbMessages}
      toValidate={false}
    />
  );
}
function MessageToValidate({ userIri }) {
  const dt = new Date();
  dt.setHours(0, 0, 0, 0);
  dt.setDate(dt.getDate() - 6);

  const { data: messages } = useQueryMessages(
    { "exists[approved]": "false", "order[createdAt]": "desc" },
    "messages_unapproved"
  );
  const nbMessages = size(messages);
  const nbNewMessages = messages.filter(function (mes) {
    return findIndex(mes["consultedBy"], { "@id": userIri }) === -1;
  }).length;
  return (
    <MessageBlockContainer
      nbNewMessages={nbNewMessages}
      nbMessages={nbMessages}
      toValidate={true}
    />
  );
}

function MessageBlockContainer({ toValidate, nbMessages, nbNewMessages }) {
  return (
    <>
      <Block className="text-center flex-grow bg-white">
        <Link
          to={`/messages${toValidate ? "/validation" : ""}`}
          className="flex flex-col flex-auto items-center justify-between flex-grow h-full"
        >
          <div
            className={`${
              toValidate ? "bg-green-800" : "bg-green-600"
            } inline-flex text-white text-3xl rounded-full font-bold w-16 h-16 items-center justify-center`}
          >
            {nbMessages}
          </div>
          {nbNewMessages || (toValidate && nbMessages > 0) ? (
            <span
              className="unread bg-red-700 rounded-full text-white text-xs absolute w-5 h-5 ml-1 -mt-3"
              style={{ marginLeft: "43px", marginTop: "5px" }}
            >
              &nbsp;
            </span>
          ) : null}
          <div className="mt-3 text-xl font-black">
            {`Message${nbMessages > 1 ? "s" : ""}${
              toValidate ? " à valider" : ""
            }`}
          </div>
          <div className="mt-2 text-base font-light">
            {"\u00A0"}
          </div>
          <Button
            className={`font-bold p-1 focus:outline-none text-white w-full block`}
          >
            voir tout
          </Button>
        </Link>
      </Block>
    </>
  );
}

export default MessagesBlock;
