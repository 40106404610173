import { get, reduce } from "lodash-es";

import Block from "./Block";
import { ReactComponent as CalendrierIcon } from "../svgs/calendrier.svg";
import { ReactComponent as CongesIcon } from "../svgs/conges.svg";
import { ReactComponent as ApplauseIcon } from "../svgs/applause.svg";
import { ReactComponent as ThinkingIcon } from "../svgs/thinking.svg";
import React from "react";
import VariationBetweenYear from "./VariationBetweenYear";
import { Link } from "react-router-dom";
import { map } from "lodash-es";

export default function StatsBlock({
  title,
  label,
  white,
  black,
  currentYear,
  previousYear,
  className = "",
  children,
  link,
  linkLabel = "voir tout",
}) {
  const textSize = getTextSizeByLength(currentYear.toString().length);
  return (
    <div className="flex flex-1">
      <Block className="w-full">
        <div className="text-center">
          <div
            className={`inline-flex ${className} ${white ? "text-white" : ""} ${
              black ? "text-black" : ""
            } ${textSize} rounded-full font-bold w-16 h-16 items-center justify-center`}
          >
            {currentYear}
          </div>
          <div className="font-bold text-2xl">{title}</div>
          {label ? <div className="text-sm font-light">{label}</div> : null}

          <VariationBetweenYear previous={previousYear} next={currentYear} />
          {children ? children : null}
          {link && (
            <Link
              to={link}
              className="pt-2 underline text-purple-500 inline-block"
            >
              {linkLabel}
            </Link>
          )}
        </div>
      </Block>
    </div>
  );
}

function getTextSizeByLength(length) {
  if (length <= 2) {
    return "text-3xl";
  }
  if (length > 2) {
    return "text-2xl";
  }
  if (length > 3) {
    return "text-xl";
  }
  if (length > 4) {
    return "text-lg";
  }
  return "text-base";
}

export function RequestsStats(props) {
  return (
    <StatsBlock
      {...props}
      className="bg-purple-500"
      title="Demandes"
      label="depuis un an"
      white
    />
  );
}

function calculStatsMessages(statsMessages) {
  const stats = reduce(
    statsMessages,
    (acc, { positive = 0, negative = 0 }, type) => {
      acc.total += positive + negative;
      acc.totalByType[type] = { number: positive + negative };
      return acc;
    },
    { total: 0, totalByType: {} }
  );
  if (stats.total > 0) {
    for (const type in stats.totalByType) {
      stats.totalByType[type].percent = Math.round(
        (stats.totalByType[type].number / stats.total) * 100
      );
    }
  }
  return stats;
}

export function MessagesStats({ title, children, ...props }) {
  const statCurrentYear = calculStatsMessages(props.currentYear);
  const statPreviousYear = calculStatsMessages(props.previousYear);

  return (
    <StatsBlock
      {...props}
      currentYear={statCurrentYear.total}
      previousYear={statPreviousYear.total}
      className="bg-purple-500"
      title={title}
      label="depuis un an"
      white
    >
      <div className="flex lg:justify-around py-6 flex-col lg:flex-row">
        {map(statCurrentYear.totalByType, (val, type) => (
          <div
            className="flex-col lg:flex-1 py-2 flex font-bold text-purple-400 text-center border-b lg:border-b-0 lg:border-r border-purple-400 last:border-0"
            key={type}
          >
            <span>{val.percent}%</span>
            <span>{type}</span>
          </div>
        ))}
      </div>
      <GratitudeRateStats currentYear={props.currentYear} title={title} />
      {children}
    </StatsBlock>
  );
}

function calculStatsGratitude(statsGratitude) {
  const stats = reduce(
    statsGratitude,
    (acc, { positive = 0, negative = 0 }) => {
      acc.positive += positive;
      acc.negative += negative;
      acc.total += positive + negative;
      return acc;
    },
    { positive: 0, negative: 0, total: 0, tauxPositive: 0, tauxNegative: 0 }
  );
  if (stats.total > 0) {
    stats.tauxPositive = Math.round((stats.positive / stats.total) * 100);
    stats.tauxNegative = Math.round((stats.negative / stats.total) * 100);
  }
  return stats;
}

export function GratitudeRateStats(props) {
  const statCurrentYear = calculStatsGratitude(props.currentYear);
  const title = props.title;

  return (
    <div className="flex">
      <div className="w-full text-center">
        <div className="flex flex-col py-6">
          <div className="font-bold text-2xl mb-6">Taux de gratitude</div>
          <div className="flex justify-around">
            <div className="flex-col flex-1 flex font-bold text-purple-400 text-center items-center  border-r border-purple-400">
              <ApplauseIcon className="text-green-600 w-5 h-5 fill-current mb-2" />
              <span className="text-green-600">
                {statCurrentYear.tauxPositive}% d'encouragement
              </span>
            </div>
            <div className="flex-col flex-1 flex font-bold text-purple-400 text-center items-center">
              <ThinkingIcon className="text-purple-400 w-5 h-5 fill-current mb-2" />
              <span className="text-purple-400">
                {title === "Messages reçus"
                  ? `${statCurrentYear.tauxNegative}% occasion de grandir`
                  : `${statCurrentYear.tauxNegative}% occasion de faire grandir`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function DirectionMessagesStats(props) {
  return (
    <StatsBlock
      className="bg-blue-500"
      title="Mots du dirigeant"
      label="rédigés depuis un an"
      white
      {...props}
    />
  );
}

export function EventsStats(props) {
  return (
    <StatsBlock
      className="bg-yellow-500"
      title="Evénements"
      label="ont eu lieu dans l’entreprise depuis un an"
      black
      {...props}
    />
  );
}

function calculStatsRequests(statsRequests) {
  const stats = reduce(
    statsRequests,
    (acc, val, type) => {
      acc.total += val;
      acc.totalByType[type] = { number: val };
      return acc;
    },
    { total: 0, totalByType: {} }
  );
  if (stats.total > 0) {
    for (const type in stats.totalByType) {
      stats.totalByType[type].percent = Math.round(
        (stats.totalByType[type].number / stats.total) * 100
      );
    }
  }
  return stats;
}

export function RequestsStatsByType(props) {

  const statCurrentYear = calculStatsRequests(props.currentYear);
  const statPreviousYear = calculStatsRequests(props.previousYear);
  
  return (
    <StatsBlock
      className="bg-purple-400"
      title="Demandes"
      label="dans l’entreprise depuis un an"
      white
      {...props}
      currentYear={statCurrentYear.total}
      previousYear={statPreviousYear.total}
    >
      <div className="flex lg:justify-around py-6 flex-col lg:flex-row">
          {map(statCurrentYear.totalByType, (val, type) => (
          <div
            className="flex-col lg:flex-1 py-2 flex font-bold text-purple-400 text-center border-b lg:border-b-0 lg:border-r border-purple-400 last:border-0"
            key={type}
          >
            <span>{val.percent}%</span>
            <span>{type}</span>
          </div>
        ))}

      </div>
    </StatsBlock>
  );
}

export function AbsencesStats(props) {
  const totalCurrentYear = reduce(
    props.currentYear,
    (acc, next) => {
        if(next[0]) {
            acc += next[0];
        }
        if(next[1]) {
            acc += next[1];
        }
        return acc;
    },
    0
  );
  const totalPreviousYear = reduce(
    props.previousYear,
    (acc, next) => {
        if(next[0]) {
            acc += next[0];
        }
        if(next[1]) {
            acc += next[1];
        }
        return acc;
    },
    0
  );
  
  //const conges = get(props, "currentYear[1]", 0);
  const conges = get(props, "currentYear[1][0]", 0);
  //const congesLastYear = get(props, "previousYear['Congés payés']");
  const congesLastYear = get(props, "previousYear[1][0]");
  //const absences = get(props, "currentYear[0]", 0) + get(props, "currentYear['']", 0);
  //const absencesLastYear = get(  props, "previousYear['Absences hors congés payés']" );
  const absences = get(props, "currentYear[0][0]", 0);
  const absencesLastYear = get(  props, "previousYear[0][0]", 0 );
  
  const absencesAT = get(props, "currentYear[0][1]", 0);
  const absencesATLastYear = get(props, "previousYear[0][1]", 0);

  
  return (
    <StatsBlock
      className="bg-purple-400"
      title="Absences"
      label="dans l’entreprise depuis un an"
      white
      {...props}
      currentYear={totalCurrentYear}
      previousYear={totalPreviousYear}
    >
      <div className="flex justify-center mt-4">
        <div className="flex flex-col flex-1 border-r border-purple-200 px-2 sm:px-4 text-purple-400 text-center">
          <div>
            <CongesIcon className="w-8 h-8 sm:w-10 sm:h-10 text-purple-500 fill-current mx-auto mb-3" />
          </div>
          {conges ? (
            <div className="mt-auto font-bold leading-tight text-sm sm:text-base">
              {Math.round((conges / totalCurrentYear) * 100)} %
              <br />
              {conges} Congé(s)
            </div>
          ) : null}
          <VariationBetweenYear previous={congesLastYear} next={conges} />
        </div>
        <div className="flex flex-col flex-1 border-r border-purple-200 px-2 sm:px-4 text-purple-400 text-center">
          <div>
            <CalendrierIcon className="w-8 h-8 sm:w-10 sm:h-10 text-purple-500 fill-current stroke-current mx-auto mb-3" />
          </div>
          {absencesAT ? (
            <div className="mt-auto font-bold leading-tight text-sm sm:text-base">
              {Math.round((absencesAT / totalCurrentYear) * 100)} %
              <br />
              {absencesAT} Absence(s) liée(s) à AT
            </div>
          ) : (
            <div className="mt-auto font-bold leading-tight text-sm sm:text-base">
            Aucune absence liée à AT
            </div>
          )}
          <VariationBetweenYear previous={absencesATLastYear} next={absencesAT} />
        </div>
        <div className="flex flex-col flex-1 border-r border-purple-200 px-2 sm:px-4 text-purple-400 text-center">
          <div>
            <CalendrierIcon className="w-8 h-8 sm:w-10 sm:h-10 text-purple-500 fill-current stroke-current mx-auto mb-3" />
          </div>
          {absences ? (
            <div className="mt-auto font-bold leading-tight text-sm sm:text-base">
              {Math.round((absences / totalCurrentYear) * 100)} %
              <br />
              {absences} Absence(s) hors AT
            </div>
          ) : null}
          <VariationBetweenYear previous={absencesLastYear} next={absences} />
        </div>
      </div>
    </StatsBlock>
  );
}

export function HiringStats(props) {
  return (
    <StatsBlock
      className="bg-teal-400"
      title="Arrivées"
      label="depuis un an"
      white
      {...props}
    />
  );
}

export function WorkAccidentStats(props) {
  return (
    <StatsBlock
      className="bg-purple-400"
      title="Accidents du travail"
      label="depuis un an"
      white
      {...props}
    />
  );
}

export function OvertimeStats({ currentYear, ...props }) {
  return (
    <StatsBlock
      className="bg-purple-400"
      title="Heures supplémentaires"
      white
      currentYear={currentYear.toLocaleString("fr-FR")}
      {...props}
    />
  );
}
export function DeparturesStats(props) {
  return (
    <StatsBlock
      className="bg-orange-400"
      title="Départs"
      label="depuis un an"
      white
      {...props}
    />
  );
}
