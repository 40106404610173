import queryWithToken from "./index";
import { queryCache, useMutation, useQuery } from "react-query";
import { get, map } from "lodash-es";
import { Module, ModuleDomain } from "../types/module";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "modules";
//

type FiltersType = {
  pagination?: boolean;
  page?: number | null;
  "order[module.actions.label]"?: "ASC" | "DESC";
};

export function useQueryModules(filters?: FiltersType) {
  const { data, ...rest } = useQuery<Module, [string, FiltersType?], Error>(
    [ressourceKey, filters],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filters,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data: get(data, "hydra:member") as ModuleDomain[],
    ...rest,
  };
}
export function useQueryModulesDomain(filters?: FiltersType) {
  const { data, ...rest } = useQuery<Module, [string, FiltersType?], Error>(
    [ressourceKey, filters],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/module_domains`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filters,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data: map(
      get(data, "hydra:member", []) as ModuleDomain[],
      "module"
    ) as Module[],
    ...rest,
  };
}

export function useActiveModule() {
  return useMutation(activeModuleById.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: () => {
      queryCache.refetchQueries([ressourceKey], { force: true });
    },
  });
}
const activeModuleById = (
  key: string,
  { id, data }: { id: number; data: any }
) => {
  return queryWithToken(
    `${process.env.REACT_APP_API_URL}/${key}/${id}/toggle`,
    {
      method: "PATCH",
      data,
    }
  );
};
