import React from "react";
import { Helmet } from "react-helmet";
import { map, size } from "lodash-es";
import Spinner from "../components/Spinner";
import { useDesktop } from "../hooks/useDesktop";
import { useQuerySynchronisationErrors } from "../api/synchronisationError";
import Block from "../components/Block";
import Alert from "../components/Alert";
import { HeaderBar } from "../components/HeaderBar";
import {useQueryOrganizationDocuments} from "../api/organizationDocument";
import ExternalLink from "../components/ExternalLink";

function limitStringSize(string) {
  if (typeof string !== 'string') {
      return null;
  }

  if (string.length > 70) {
      return string.substring(0, 70) + "...";
  }
  
  return string;
}

function NewOrganizationDocuments() {
  const isDesktop = useDesktop();
  const { data } = useQueryOrganizationDocuments({
      'order[idDoc]': 'DESC',
      'pagination': true,
      'itemsPerPage': 10,
      'page': 1
  });

//  console.log(data);
  return (
    <div>
      <Helmet>
        <title>Derniers documents entreprise</title>
      </Helmet>
      <HeaderBar>Derniers documents entreprise</HeaderBar>
      <div
        className={"xl:w-8/12 mx-auto px-0 sm:px-8 md:px-24 xl:px-0 "}
        style={isDesktop ? { maxWidth: "900px" } : {}}
      >
        <div className="px-8 mb-48 mt-4">
          <React.Suspense fallback={<Spinner />}>
            {size(data) > 0 ? (
              <Block>
                {map(data, (document, index) => {
                  const date = new Date(document?.date);
             
                  return (
                      <ExternalLink to={document?.publicLink}>
                         <div key={index} className={`pb-2`}>
                              <div
                                  className={`${
                                      index === 0 ? "" : "border-t-2 border-solid pt-2"
                                  }`}
                              >
                                  <div className={'font-bold uppercase'}>{document?.comment ? limitStringSize(document?.comment) : null}</div>
                                  <div>{document?.date ? date.toLocaleDateString("fr") : null} {document?.extension ? "(Fichier ." + document?.extension.toUpperCase() + ")" : null}</div>
                              </div>
                          </div>
                      </ExternalLink>
                  );
                })}
              </Block>
            ) : (
              <div className="mt-12">
                <Alert
                  type="warning"
                  message="Aucun document"
                />
              </div>
            )}
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}
export default NewOrganizationDocuments;
