import { Form, Formik } from "formik";
import React, { useState } from "react";
import {filter, find, get, map, isEmpty} from "lodash-es";
import { useDeleteAbsence, useUpdateAbsence } from "../../api/absence";

import Alert from "../Alert";
import { ReactComponent as CalendrierIcon } from "../../svgs/calendrier-vide.svg";
import EditButton from "./EditButton";
import HistoryItem from "./HistoryItem";
import Input, { DateTimePickerInput, TimePickerInput } from "../Input";
import Select from "../Select";
import * as Yup from "yup";
import DownloadOrUpload from "../DownloadOrUpload";
import {
  downloadAbsenceDocument,
  useCreateAbsenceDocument,
} from "../../api/absenceDocument";
import { dateCompare } from "../../regex/date";
import {
    usePermissionsChecker,
    useUseExternalDocumentManagement,
    useCollaboratorId, useIsAdmin,
} from "../../contexts/permissions";
import { DisplayCheckbox } from "../Checkbox";
import {
  getMessageLabel,
  getStatusLabel,
  getTransitionLabel,
  periodLabels,
} from "../../utils/absenceStatus";
import { useGetCollaboratorAbsenceTypes } from "../../api/collaborator";
import Button from "../Button";
import { getFirstnameLastnameJob } from "../../utils/names";
import { findInList } from "../../utils/array";
import { minutesToHoursString } from "../../utils/date";
import FileDownloadLine from "../Button/FileDownloadLine";
import {useDeleteRecurrentAbsencesAggregation} from "../../api/recurrentAbsencesAggregations";
import {useQueryValidatorById} from "../../api/validator";

function stringToMinute(hour) {
  if (typeof hour.getMonth !== "function") return null;

  return hour.getHours() * 60 + hour.getMinutes();
}

const recurrentAbsenceTypes = {
    weekly: "Toutes les semaines",
    triweekly: "Toutes les 3 semaines",
    monthly: "Tous les mois",
    evenweeks: "Semaines paires",
    oddweeks: "Semaines impaires",
}

const recurrentAbsencePeriods = {
    firsttwo: "2 premières semaines",
    firstthree: "3 premières semaines",
}

const recurrentAbsenceDays = {
    0: "Lundi Matin",
    0.5: "Lundi Après-Midi",
    1: "Mardi Matin",
    1.5: "Mardi Après-Midi",
    2: "Mercredi Matin",
    2.5: "Mercredi Après-Midi",
    3: "Jeudi Matin",
    3.5: "Jeudi Après-Midi",
    4: "Vendredi Matin",
    4.5: "Vendredi Après-Midi",
    5: "Samedi Matin",
    5.5: "Samedi Après-Midi",
    6: "Dimanche Matin",
    6.5: "Dimanche Après-Midi",
}

const periodsHalfDay = [
  { label: "Matin", value: "morning" },
  { label: "Après-midi", value: "afternoon" },
  { label: "Nuit", value: "night" },
];

function getCommentsMandatory({ status, type, history }) {
  if (status === "prevalidated" && type?.needCommentPreValidation) {
    return filter(history, { transition: "prevalidate" });
  } else if (
    (status === "validated" && type?.needCommentPreValidation) ||
    type?.needCommentValidation
  ) {
    return filter(history, { transition: "validate" });
  }
  return [];
}

const AbsenceCardSubTitle = ({ absence, isRecurrent = false }) => {
  const docNotif = !absence?.absenceDocument && find(absence.absenceNotifications, (notif) => {
    return notif.message === "absence_notification.need_documents";
  });

  const [messageLabel, messageType] = getMessageLabel(
    get(docNotif, "message", "")
  );

  const autoValidated = !absence?.type?.needValidation;

  return (
    <>
      <div>
        {isRecurrent ? "Prochaine à " : 'À'} partir du :{" "}
        {absence.startDate && new Date(absence.startDate).toLocaleDateString()}
        {absence.startPeriod && " " + periodLabels[absence.startPeriod]}
        {absence.startHour && " à " + minutesToHoursString(absence.startHour)}
        {absence.endDate &&
          " reprise le : " + new Date(absence.endDate).toLocaleDateString()}
        {absence.endPeriod && " " + periodLabels[absence.endPeriod]}
        {absence.endHour && " à " + minutesToHoursString(absence.endHour)}
      </div>
      {map(getCommentsMandatory(absence), (historyItem) => (
        <div>
          Commentaire du{" "}
          {historyItem.date
            ? new Date(historyItem.date).toLocaleDateString()
            : ""}
          : {historyItem.comment}
        </div>
      ))}
      <div className={"flex flex-wrap text-white"}>
        {!autoValidated ? (
          <>
            {absence.status === "created" ||
            absence.status === "prevalidated" ? (
              <div className={"bg-orange-250 px-2 py-1 mb-1 mr-1 rounded"}>
                {getStatusLabel(get(absence, "status"), get(absence, "type"))}
              </div>
            ) : null}
            {absence.status === "validated" ? (
              <div className={"bg-green-800 px-2 py-1 mb-1 mr-1 rounded"}>
                {getStatusLabel(get(absence, "status"), get(absence, "type"))}
              </div>
            ) : null}
            {absence.status === "refused" ? (
              <div className={"bg-red-800 px-2 py-1 mb-1 mr-1 rounded"}>
                {getStatusLabel(get(absence, "status"), get(absence, "type"))}
              </div>
            ) : null}
          </>
        ) : null}

        {docNotif ? (
          <div
            className={`${
              messageType === "warning"
                ? "bg-orange-250"
                : messageType === "success"
                ? "bg-green-800"
                : "bg-red-800"
            } px-2 py-1 mb-1 mr-1 rounded`}
          >
            {messageLabel}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default function AbsenceHistoryItem({
  absence = {},
  isLeft,
  collaborator,
}) {
  const isRecurrent = get(absence, "recurrent", false);
  const canEdit = usePermissionsChecker({
    permissions: [
      "kdix.actions.absence.edit",
      "kdix.actions.absence.edit.agency",
      "kdix.actions.absence.edit.department",
      "kdix.actions.absence.edit.service",
      "kdix.actions.absence.edit.own",
    ],
  }) && !isRecurrent;

  //Avoir ou non le droit de supprimer une absence est définit dans le ticket #992
  const canDelete =
    canEdit &&
    get(absence, "status", "") !== "refused" ;
  const [isEditing, setIsEditing] = useState(false);
  const { data: typesAbsence } = useGetCollaboratorAbsenceTypes(
    collaborator.id
  );

  const isAdmin = useIsAdmin();

  const currentUserId = useCollaboratorId();
  const { data: validatorInfos } = useQueryValidatorById(currentUserId);
  const currentUserIsValidator = !isEmpty(validatorInfos?.collaboratorsCanBeValidate)
      || !isEmpty(validatorInfos?.collaboratorsCanBePreValidate) || isAdmin;

  const [update, { error }] = useUpdateAbsence();
  const [deleteAbsence, { status }] = useDeleteAbsence(true);
  const [deleteRecurrentAbsences] = useDeleteRecurrentAbsencesAggregation(true);
  const [createDocument] = useCreateAbsenceDocument();
  const useExternalDocumentManagement = useUseExternalDocumentManagement();
  const validation = Yup.object().shape({
    type: Yup.object().shape({
      value: Yup.string().required("Requis"),
    }),
    startDate: Yup.date()
      .required("Requis")
      .test(
        "prevenance",
        "La date demandée ne respecte pas le délai de prévenance",
        function (value) {
          if (!this.parent.type.value || !value) {
            return true;
          }
          const type = find(
            typesAbsence,
            (obj) => obj["@id"] === this.parent.type.value
          );
          const delay = get(type, "thoughtfulnessDelay", 0);
          if (delay === 0) {
            return true;
          }
          const now = new Date();
          const start = new Date(value);
          const diffDay = Math.floor((start - now) / (1000 * 60 * 60 * 24));
          return diffDay >= delay;
        }
      ),
    endDate: Yup.date()
      .required("Requis")
      .test("date-match", "Doit être postérieure à la date de début", function (
        value
      ) {
        return dateCompare(this.parent.startDate, value, true);
      }),
    comment: Yup.string().test("needMotive", "Requis", function (value) {
      const type = find(
        typesAbsence,
        (obj) => obj["@id"] === this.parent.type.value
      );
      const needMotive = get(type, "needMotive", false);
      return !(needMotive && (!value || value === ""));
    }),
  });
  if (status === "success") return null;
  let initStartHour = new Date();
  if (absence.startHour) {
    initStartHour.setHours(
      Math.floor(absence.startHour / 60),
      absence.startHour % 60
    );
  }
  let initEndHour = new Date();
  if (absence.endHour) {
    initEndHour.setHours(
      Math.floor(absence.endHour / 60),
      absence.endHour % 60
    );
  }


  return (
    <Formik
      initialValues={{
        type: {
          label: absence.type?.label || "",
          value: absence.type?.["@id"] || "",
        },
        comment: absence.comment || "",
        startDate: get(absence, "startDate")
          ? new Date(get(absence, "startDate"))
          : "",
        endDate: get(absence, "endDate")
          ? new Date(get(absence, "endDate"))
          : "",
        file: "",
        startHour: absence.startHour ? initStartHour : "",
        endHour: absence.endHour ? initEndHour : "",
        startPeriod: get(absence, "startPeriod", false)
          ? findInList(get(absence, "startPeriod", ""), periodsHalfDay)
          : {
              label: "",
              value: "",
            },
        endPeriod: get(absence, "endPeriod", false)
          ? findInList(get(absence, "endPeriod", ""), periodsHalfDay)
          : {
              label: "",
              value: "",
            },
        recurrent: get(absence, "recurrent", false),
        numberOfDays: get(absence, "numberOfDays", 0),
      }}
      enableReinitialize={true}
      validationSchema={get(absence, "recurrent", false) ? null : validation}
      onSubmit={async ({ file, ...values }, actions) => {
        try {
          let { id: newId } = absence;
          if (!get(absence, "recurrent", false)) {
              let { id: newId } = await update({
                  id: absence.id,
                  data: {
                      type: values.type.value || null,
                      startDate: values.startDate,
                      endDate: values.endDate,
                      startHour: stringToMinute(values.startHour),
                      endHour: stringToMinute(values.endHour),
                      startPeriod: values.startPeriod.value
                          ? values.startPeriod.value
                          : null,
                      endPeriod: values.endPeriod.value ? values.endPeriod.value : null,
                      comment: values.comment,
                  },
              });
          }

          if (newId && file) {
            await createDocument({
              file: file,
              absenceId: newId,
            });
          }
        } catch (error) {
          map(get(error, "violations"), (e) => {
            actions.setFieldError(e.propertyPath, e.message);
          });
        } finally {
          actions.setSubmitting();
          setIsEditing(false);
        }
      }}
    >
      {({ values, isSubmitting, resetForm, setSubmitting, setFieldValue, isValidating }) => {
        const typeSelected = find(typesAbsence, (a) => {
          return a["@id"] === get(values, "type.value", null);
        });
        const timesManagement = get(
          typeSelected,
          "timeManagements[0].slug",
          []
        );
        const canEdit =
          absence?.status !== "validated" ||
          absence?.type?.needValidation !== true;
        const needMotive = absence?.type?.needMotive;
          return (
          <Form>
            <HistoryItem
              title={
                get(values, "type.label", "") !== ""
                  ? `Absence - ${get(values, "type.label", "")}`
                  : !isRecurrent ? "Absence"
                        : `Absence récurrente du
                        ${new Date(get(absence, "recurrentAbsencesAggregation.startDate", "")).toLocaleDateString()} au
                        ${new Date(get(absence, "recurrentAbsencesAggregation.endDate", "")).toLocaleDateString()}`
              }
              Icon={CalendrierIcon}
              backgroundIcon="bg-pink-150"
              date={
                absence.startDate
                  ? new Date(absence.startDate).toLocaleDateString()
                  : ""
              }
              subTitle={<AbsenceCardSubTitle absence={absence} isRecurrent={isRecurrent} />}
              isToggle={true}
              textColor={"text-pink-150"}
              isLeft={isLeft}
            >
              {needMotive && absence.comment && (
                  <span>
                      Motif : {absence.comment}
                  </span>
              )}
              {absence.status !== "created" &&
              get(absence, "exceptional", false) ? (
                <span>Accordée à titre exceptionnel</span>
              ) : null}
              {isEditing && (
                <Select
                  label="Type d'absence"
                  name="type"
                  options={map(typesAbsence, (type) => ({
                    label: type.label,
                    value: get(type, "@id"),
                  }))}
                  value={values.type}
                  isDisabled={!isEditing}
                />
              )}
              <div>
                {map(get(absence, "history", []), (historyItem) => {
                  return (
                    <div
                      key={historyItem.id}
                      className={
                        "border-solid border-b border-black last:border-b-0 py-3"
                      }
                    >
                      <p>
                        Votre demande du{" "}
                        {new Date(
                          get(absence, "createdAt")
                        ).toLocaleDateString()}{" "}
                        a été{" "}
                        <span className={"font-bold"}>
                          {getTransitionLabel(
                            get(historyItem, "transition", "")
                          ).toLowerCase()}
                        </span>{" "}
                        par{" "}
                        {getFirstnameLastnameJob(historyItem, "collaborator")}{" "}
                        le{" "}
                        {get(historyItem, "date")
                          ? new Date(
                              get(historyItem, "date")
                            ).toLocaleDateString()
                          : ""}
                      </p>
                      {get(historyItem, "comment", false) ? (
                        <p>Commentaire : {get(historyItem, "comment", "")}</p>
                      ) : null}
                    </div>
                  );
                })}
              </div>

              {isEditing ? (
                <div className={"flex flex-wrap"}>
                  <div className={"w-full lg:w-1/2 lg:pr-2"}>
                    <DateTimePickerInput
                      name="startDate"
                      label="Date de l'absence"
                      fullWidth={true}
                      readOnly={!isEditing}
                      onChange={(value) => {
                        //En attendant la gestion à l'heure/tiers journée on force les heures de début et fin à la journée
                        value.setHours(0, 0, 0, 0);
                        setFieldValue("startDate", value);
                      }}
                    />
                  </div>
                  <div className={"w-full lg:w-1/2 lg:pl-2"}>
                    <DateTimePickerInput
                      name="endDate"
                      label="Date de reprise"
                      fullWidth={true}
                      readOnly={!isEditing}
                      onChange={(value) => {
                        //En attendant la gestion à l'heure/tiers journée on force les heures de début et fin à la journée
                        value.setHours(0, 0, 0, 0);
                        setFieldValue("endDate", value);
                      }}
                    />
                  </div>

                  {timesManagement.indexOf("hour") !== -1 && (
                    <>
                      <div className={"w-full lg:w-1/2 lg:pr-2"}>
                        <TimePickerInput
                          name="startHour"
                          label="Heure de début"
                          fullWidth={true}
                          readOnly={!isEditing}
                        />
                      </div>
                      <div className={"w-full lg:w-1/2 lg:pr-2"}>
                        <TimePickerInput
                          name="endHour"
                          label="Heure de retour"
                          fullWidth={true}
                          readOnly={!isEditing}
                        />
                      </div>
                    </>
                  )}
                  {timesManagement.indexOf("half-day") !== -1 && (
                    <>
                      <div className={"w-full lg:w-1/2 lg:pr-2"}>
                        <Select
                          label="Période de début"
                          name="startPeriod"
                          options={periodsHalfDay}
                          value={
                            values.startPeriod.value ? values.startPeriod : null
                          }
                          isDisabled={!isEditing}
                          isClearable
                        />
                      </div>
                      <div className={"w-full lg:w-1/2 lg:pr-2"}>
                        <Select
                          label="Période de reprise"
                          name="endPeriod"
                          options={periodsHalfDay}
                          value={
                            values.endPeriod.value ? values.endPeriod : null
                          }
                          isDisabled={!isEditing}
                          isClearable
                        />
                      </div>
                    </>
                  )}

                  {get(typeSelected, "needMotive", false) && (
                    <div className={"w-full"}>
                      <Input
                        type="text"
                        name="comment"
                        label="Motif de l'absence"
                        readOnly={!isEditing}
                      />
                    </div>
                  )}

                  {get(typeSelected, "recurrent", false) && (
                    <div className={"w-full"}>
                      <DisplayCheckbox
                        readOnly={true}
                        checked={true}
                        type="checkbox"
                        name="recurrent"
                        label="Absence récurrente"
                      />
                    </div>
                  )}

                  {error ? (
                    <div className="my-2">
                      <Alert
                        type="error"
                        message={get(error, "title")}
                        details={get(error, "description")}
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}
              {canEdit && !isRecurrent && !useExternalDocumentManagement ? (
                <DownloadOrUpload
                  uploadLabel={
                    typeSelected?.needCertificate
                      ? "Ajouter un justificatif"
                      : "Ajouter un fichier"
                  }
                  name="file"
                  accept="image/png, image/jpeg, application/pdf"
                  isSubmitting={isSubmitting}
                  submitButton={true}
                  downloadFn={!!get(absence, "absenceDocument", false)}
                />
              ) : null}
              {absence?.absenceDocument && (
                <div className="mb-4">
                  <FileDownloadLine
                    label="Justificatif d'abscence"
                    downloadFn={downloadAbsenceDocument.bind(
                      null,
                      absence?.absenceDocument.id,
                      absence?.absenceDocument.label,
                      absence?.absenceDocument.extension
                    )}
                  />
                </div>
              )}
              {currentUserIsValidator && canDelete ? (
                <EditButton
                  isSubmitting={isSubmitting}
                  isEditing={isEditing}
                  onDelete={async () => {
                      const res = window.confirm(
                        "Êtes-vous sûr de vouloir supprimer cette absence ?"
                      );
                      if (res) {
                        setSubmitting(true);
                        await deleteAbsence(absence.id);
                        setSubmitting(false);
                      }
                  }}
                  onCancel={() => {
                    resetForm();
                    setIsEditing(!isEditing);
                  }}
                  onClick={() => {
                    setIsEditing(!isEditing);
                  }}
                  permissionsEdit={[
                    "kdix.actions.absence.edit",
                    "kdix.actions.absence.edit.agency",
                    "kdix.actions.absence.edit.department",
                    "kdix.actions.absence.edit.service",
                    "kdix.actions.absence.edit.own",
                  ]}
                />
              ) : null}
              {isRecurrent ? (
                  <div>
                      <p>
                          Description : {absence.recurrentAbsencesAggregation.label}
                      </p>
                      <p>
                          Récurrence : {recurrentAbsenceTypes[absence.recurrentAbsencesAggregation.type]}
                      </p>
                      {["days", "firsttwo", "firstthree", "evenweeks", "oddweeks"].includes(absence.recurrentAbsencesAggregation.period) && (
                          <p>
                              Période d'absence : {["days", "evenweeks", "oddweeks"].includes(absence.recurrentAbsencesAggregation.period) ?
                              (map(absence.recurrentAbsencesAggregation.days, (day, index) => {
                                  return (index > 0 ? ", " : "") + recurrentAbsenceDays[day];
                              })) :
                              recurrentAbsencePeriods[absence.recurrentAbsencesAggregation.period]}
                          </p>
                      )}
                      {canEdit && !useExternalDocumentManagement ? (
                          <DownloadOrUpload
                              uploadLabel={"Ajouter un justificatif"}
                              name="file"
                              accept="image/png, image/jpeg, application/pdf"
                              isSubmitting={isSubmitting}
                              submitButton={true}
                              downloadFn={!!get(absence, "recurrentAbsencesAggregation.absenceDocument", false)}
                          />
                      ) : null}
                      {absence?.recurrentAbsencesAggregation?.absenceDocument && (
                          <div className="mb-4">
                              <FileDownloadLine
                                  label="Justificatif d'abscence"
                                  downloadFn={downloadAbsenceDocument.bind(
                                      null,
                                      absence?.recurrentAbsencesAggregation?.absenceDocument.id,
                                      absence?.recurrentAbsencesAggregation?.absenceDocument.label,
                                      absence?.recurrentAbsencesAggregation?.absenceDocument.extension
                                  )}
                              />
                          </div>
                      )}
                      <Button
                          className={`btn--error inline-block flex ml-auto`}
                          isSubmitting={isSubmitting}
                          isForm={true}
                          type="button"
                          textLabel="Supprimer la récurrence"
                          onClick={async () => {
                              const res = window.confirm(
                                  "Êtes vous sûr de vouloir annuler toutes les périodes d'absence ?"
                              );
                              if (res) {
                                  setSubmitting(true);
                                  await deleteRecurrentAbsences(absence.recurrentAbsencesAggregation.id);
                                  setSubmitting(false);
                              }
                          }}
                      />
                  </div>
              ) : null}
            </HistoryItem>
          </Form>
        );
      }}
    </Formik>
  );
}
