import { map } from "lodash-es";

import React from "react";
import Radio from "./Radio";
import { useField } from "formik";

function Label({ meta, label }) {
  return (
    <label
      className={`block ${
        meta.touched && meta.error ? "text-red-500" : `text-black`
      } text-sm font-bold`}
    >
      {label}
    </label>
  );
}

function RadioGroup({ options, label = null, ...props }) {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  return (
    <>
      {label && <Label meta={meta} label={label} />}
      <div className="flex gap-3">
        {map(options, ({ value: valueRadio, label: labelRadio }) => (
          <Radio
            {...props}
            key={valueRadio}
            label={labelRadio}
            name={field.name}
            value={valueRadio}
            checked={valueRadio === field.value}
            onChange={({ target: { checked } }) => {
              if (checked) {
                setValue(valueRadio);
              }
            }}
          />
        ))}
      </div>
    </>
  );
}

export default RadioGroup;
