import { filter, flatMap, map } from "lodash-es";
import Select from "./Select";
import React, { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { useQueryCollaboratorsList } from "../api/collaborator";
import { getFirstnameLastnameJob } from "../utils/names";

export default function SelectCollaborator({
  value,
  name,
  label,
  isDisabled = false,
  exclude = null,
  isMulti,
  valueId = false,
  allowSelectAll = false,
  isUser = false,
  queryFilters = {},
  addOptions = [],
  ...props
}) {
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query]
  );
  const addFilters = isUser
    ? {
        "exists[linkedUser]": "true",
      }
    : {};
  const {
    data,
    fetchMore,
    canFetchMore,
    isFetchingMore,
    isFetching,
  } = useQueryCollaboratorsList(
    {
      "order[lastname]": "asc",
      active: "true",
      orSearch_fullname: debouncedQuery,
      ...addFilters,
      ...queryFilters,
    },
    false
  );
  const collaborators = flatMap(data, (page) => {
    if (exclude) {
      return filter(page["hydra:member"], (m) => m["@id"] !== exclude);
    }
    return page["hydra:member"];
  });

  useEffect(() => {
    //Si on doit pouvoir tout sélectionner on doit attendre d'avoir récupéré toutes les entrées
    if (allowSelectAll && !isDisabled) {
      if (
        canFetchMore !== false &&
        isFetchingMore !== true &&
        isFetching !== true
      ) {
        fetchMore();
      }
    }
  }, [
    allowSelectAll,
    isDisabled,
    canFetchMore,
    isFetchingMore,
    isFetching,
    fetchMore,
  ]);
  return (
    <Select
      name={name}
      label={label}
      options={[
        ...map(collaborators, (collaborator) => ({
          label: getFirstnameLastnameJob({ collaborator }, "collaborator"),
          value: valueId
            ? collaborator.id
            : isUser
            ? collaborator?.linkedUser?.["@id"]
            : collaborator["@id"],
        })),
        ...addOptions,
      ]}
      value={isMulti ? value : value.value !== "" ? value : null}
      onInputChange={(value) => {
        setQuery(value);
      }}
      isMulti={isMulti}
      {...props}
      onMenuScrollToBottom={() => {
        if (
          canFetchMore !== false &&
          isFetchingMore !== true &&
          isFetching !== true
        ) {
          fetchMore();
        }
      }}
      isDisabled={isDisabled}
      isLoading={!collaborators || isFetchingMore || isFetching}
      allowSelectAll={allowSelectAll}
    />
  );
}
