import React, { useState } from "react";
import Modal from "../../components/Modal";
import MedicalVisitForm, {
  EditMedicalVisitForm,
} from "../../forms/MedicalVisitForm";
import { get, isEmpty } from "lodash-es";
import { useDesktop } from "../../hooks/useDesktop";
import { CollaboratorContext } from "../../contexts/collaborator";
import { PermissionChecker } from "../../contexts/permissions";
import ExpansionPanel from "../../components/ExpansionPanel";
import AbsenceCollaboratorForm from "../../forms/AbsenceCollaboratorForm";
import { useGetCollaboratorValidators } from "../../api/collaborator";
import VehicleGeneralInfosLight from "../../components/VehicleGeneralInfolsLight";
import ContractInfosLight from "../../components/ContractInfoLight";

function Complement() {
  const isDesktop = useDesktop();
  const {
    personalInformation,
    collaborator,
    currentContract,
    vehicle,
  } = React.useContext(CollaboratorContext);
  const [modalMedicalOpen, setModalMedicalOpen] = useState(false);
  const { data: validators } = useGetCollaboratorValidators(
    get(collaborator, "data['id']")
  );
  const hasVehicle = !isEmpty(vehicle.data);
  return (
    <PermissionChecker
      permissions={[
        "kdix.actions.additional_information.view",
        "kdix.actions.additional_information.view.agency",
        "kdix.actions.additional_information.view.department",
        "kdix.actions.additional_information.view.service",
        "kdix.actions.additional_information.view.own",
      ]}
    >
      <div
        className={`xl:grid xl:grid-cols-3 grid-flow-row-dense grid-rows-1 xl:gap-2`}
      >
        <div>
          <PermissionChecker
            permissions={[
              "kdix.actions.medical_visit.view",
              "kdix.actions.medical_visit.view.agency",
              "kdix.actions.medical_visit.view.department",
              "kdix.actions.medical_visit.view.service",
              "kdix.actions.medical_visit.view.own",
            ]}
          >
            <ExpansionPanel title="Visite médicale" open={isDesktop}>
              <MedicalVisitForm
                setModalMedicalOpen={setModalMedicalOpen}
                medicalVisits={get(personalInformation, "data.medicalVisits")}
                personalInformationIRI={get(personalInformation, "data.@id")}
              />
            </ExpansionPanel>
          </PermissionChecker>
        </div>
        <div>
          <ExpansionPanel title="Détail du poste" open={isDesktop}>
            <ContractInfosLight contract={currentContract} />
          </ExpansionPanel>
          <PermissionChecker
            permissions={[
              "kdix.actions.absence.edit",
              "kdix.actions.absence.edit.agency",
              "kdix.actions.absence.edit.department",
              "kdix.actions.absence.edit.service",
              "kdix.actions.absence.edit.own",
            ]}
          >
            <ExpansionPanel title="Congés" open={isDesktop}>
              <AbsenceCollaboratorForm collaborator={validators} />
            </ExpansionPanel>
          </PermissionChecker>
        </div>
        <div>
          {hasVehicle && (
            <ExpansionPanel
              title="Véhicule d'entreprise actuel"
              open={isDesktop}
            >
              <VehicleGeneralInfosLight vehicle={vehicle.data} />
            </ExpansionPanel>
          )}
        </div>
        <Modal
          title="Ajouter une visite médicale"
          handleClose={(e) => {
            e.stopPropagation();
          }}
          isOpen={modalMedicalOpen}
          onRequestClose={() => setModalMedicalOpen(false)}
        >
          <div className="bg-white">
            <EditMedicalVisitForm
              personalInformationIRI={get(personalInformation, "data.@id")}
              handleSubmit={() => setModalMedicalOpen(false)}
            />
          </div>
        </Modal>
      </div>
    </PermissionChecker>
  );
}

export default Complement;
