import {forEach} from "lodash-es";
import {absenceStatus} from "./absenceStatus";

export function isMissingToday(listAbsence, collaboratorId) {
    const today = new Date().getTime();
    let isMissing = false;

    forEach(listAbsence, (absence) => {
        if (absence.collaborator.id === collaboratorId && absence.status === "validated") {
            const start = new Date(absence.startDate);
            const end = new Date(absence.endDate);

            if (absence.startHour) {
                start.setHours(
                    Math.floor(absence.startHour / 60),
                    absence.startHour % 60
                );
            }
            if (absence.endHour) {
                end.setHours(
                    Math.floor(absence.endHour / 60),
                    absence.endHour % 60
                );
            }
            if (absence.startPeriod && absence.endPeriod) {
                if (absence.startPeriod !== 'morning') {
                    start.setHours(12);
                }
                if (absence.endPeriod !== 'morning') {
                    end.setHours(12);
                }
            }
            if (today >= start.getTime() && today < end.getTime()) {
                isMissing = true;
                return 0;
            }
        }
    });
    return isMissing;
}

export function isCollaboratorAbsentToday(absence) {
    const today = new Date().getTime();
    let isMissing = false;
    
    const start = new Date(absence.startDate);
    const end = new Date(absence.endDate);

    if (absence.startHour) {
        start.setHours(
            Math.floor(absence.startHour / 60),
            absence.startHour % 60
        );
    }
    if (absence.endHour) {
        end.setHours(
            Math.floor(absence.endHour / 60),
            absence.endHour % 60
        );
    }
    if (absence.startPeriod && absence.endPeriod) {
        if (absence.startPeriod !== 'morning') {
            start.setHours(12);
        }
        if (absence.endPeriod !== 'morning') {
            end.setHours(12);
        }
    }
    if (today >= start.getTime() && today < end.getTime()) {
        isMissing = true;
    }
    
    return isMissing;
}

export function isCommentRequired(initialStatus, nextStatus, requirements) {
    let isRequired = false;
    if (initialStatus === absenceStatus.WORKFLOW_STATUS_CREATED) {
        isRequired =
            (nextStatus === absenceStatus.WORKFLOW_STATUS_REFUSED &&
                requirements.needCommentPreValidation &&
                requirements.needPreValidation) ||
            (nextStatus === absenceStatus.WORKFLOW_STATUS_REFUSED &&
                requirements.needCommentValidation &&
                !requirements.needPreValidation &&
                requirements.needValidation) ||
            (nextStatus === absenceStatus.WORKFLOW_STATUS_VALIDATED &&
                requirements.needCommentPreValidation &&
                requirements.needPreValidation) ||
            (nextStatus === absenceStatus.WORKFLOW_STATUS_VALIDATED &&
                requirements.needCommentValidation &&
                !requirements.needPreValidation &&
                requirements.needValidation);
    } else if (initialStatus === absenceStatus.WORKFLOW_STATUS_PREVALIDATED) {
        isRequired =
            (nextStatus === absenceStatus.WORKFLOW_STATUS_REFUSED &&
                requirements.needCommentValidation) ||
            (nextStatus === absenceStatus.WORKFLOW_STATUS_VALIDATED &&
                requirements.needCommentValidation);
    }

    return isRequired;
}
