import React, { useState } from "react";
import { Form, Formik } from "formik";
import {get, intersection, isEmpty, join, map, sortBy, size} from "lodash-es";
import { toast } from "react-toastify";
import Alert from "../Alert";
import Button from "../Button";
import { useToggle } from "react-use";
import Block from "../Block";
import { ReactComponent as TraiterIcon } from "../../svgs/traiter.svg";
import { ReactComponent as DeleguerIcon } from "../../svgs/deleguer.svg";
import { ReactComponent as ReporterIcon } from "../../svgs/reporter.svg";
import { ReactComponent as CommenterIcon } from "../../svgs/commenter.svg";
import { DateTimePickerInput } from "../Input";
import { useUpdateAlert } from "../../api/alerts";
import { useQueryUsers } from "../../api/user";
import Select from "../Select";
import { useUserIri } from "../../contexts/permissions";
import { getFirstnameLastnameJob } from "../../utils/names";
import * as Yup from "yup";
import { dateCompare } from "../../regex/date";
import {
  usePermissionsChecker,
} from "../../contexts/permissions";
import Textarea from "../TextArea";
import {useCreateAlertComment} from "../../api/alertComments";

function AlertItem({ alert, isArchive, archiveHook, children, setItem }) {
  const [isDisplayed, setIsDisplayed] = useState(true);
  if (!isDisplayed) {
    return null;
  }
  return (
    <div className={`p-4 bg-white`}>
      <div
        className="cursor-pointer"
        onClick={() => {
          setItem(null);
        }}
      >
        <svg
          className={`transform fill-current text-green-600 rotate-90 float-right`}
          width="20"
          height="12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.667 0L10 7.46 2.333 0 0 2.27 10 12l10-9.73L17.667 0z"
          />
        </svg>
      </div>
      <AlertForm
        alert={alert}
        archiveHook={archiveHook}
        children={children}
        setIsDisplayed={setIsDisplayed}
        isArchive={isArchive}
      />
    </div>
  );
}

function AlertForm({
  alert,
  setIsDisplayed,
  archiveHook,
  children,
  isArchive,
}) {
  const [archive, { error }] = archiveHook();
  return (
    <div className={` mx-4`}>
      <Formik
        initialValues={{}}
        onSubmit={async (values, actions) => {
          try {
            await archive({
              id: get(alert, "id"),
              data: {
                archived: !get(alert, "archived"),
              },
            });
            toast.success("Alerte modifiée avec succès");
            setIsDisplayed(false);
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              {children}

              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={error.title}
                    details={error.description}
                  />
                </div>
              ) : null}
              <Button
                className={`btn mt-6 w-full block`}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
                textLabel={isArchive ? "Réactiver" : "Archiver"}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
function translateErrorMessage(errorMessage) {
  switch (errorMessage) {
    case "The new reminder date must be superior to previous one":
      return "La nouvelle date doit être postérieure à la précédente";
    case "Cannot report an old alert":
      return "L'échéance est dépassée, impossible de reporter l'alerte";
    case "The new reminder date must be superior to current day":
      return "La nouvelle date doit être postérieure à aujourd'hui";
    case "Cannot report an archived alert":
      return "Vous ne pouvez pas reporter une alerte traitée";
    default:
      return errorMessage;
  }
}
export function AlertItemBlock({
  alert,
  title: { title, subtitles = false },
  isArchive,
  children,
  filters,
}) {
  let consulted = map(get(alert, "consultedBy"), "@id");
  const delegated = map(get(alert, "delegatedUsers"), "@id");
  const userIri = useUserIri();
  const alreadyRead = consulted.includes(userIri);
  const isDelegated = delegated.includes(userIri);
  const [on, toggle] = useToggle(false);
  const [isDisplayed, setIsDisplayed] = useState(true);
  const [delegateOn, toggleDelegate] = useToggle(false);
  const [delayOn, toggleDelay] = useToggle(false);
  const [commentateOn, toggleCommentate] = useToggle(false);
  const [updateAlert] = useUpdateAlert(filters);
  const [createAlertComment] = useCreateAlertComment();
  const { data: users } = useQueryUsers({
    "exists[collaborator]": "true",
    "order[collaborator.lastname]": "asc",
    "collaborator.active": "true",
  });
  const isPostponed = false;
  const canEdit = usePermissionsChecker({
    permissions: [
      "kdix.actions.personal_information.edit",
      "kdix.actions.personal_information.edit.agency",
      "kdix.actions.personal_information.edit.department",
      "kdix.actions.personal_information.edit.service",
      "kdix.actions.personal_information.edit.own",
    ],
  }) || !get(alert, "personalInformation");


  const transformSelect = (r) => {
    return {
      value: get(r, "@id"),
      permissions: map(r.permissions, "@id"),
      label: getFirstnameLastnameJob(r, "collaborator"),
    };
  };

  const userSelect = map(users, (user) => transformSelect(user));
  const sortedAlertComments = sortBy(alert.comments, 'id');

  if (!isDisplayed) {
    return null;
  }
  return (
    <Block className={"cursor-pointer"}>
      <div
        className={"flex"}
        onClick={(on) => {
          if (!alreadyRead) {
            consulted.push(userIri);
            updateAlert({
              id: get(alert, "id"),
              data: { consultedBy: consulted },
            });
          }
          toggle();
        }}
      >
        <div className={"lg:flex w-full"}>
          <div>
            <p className={`font-bold`}>{title}</p>
            {map(subtitles, (subtitle, i) => (
              <p key={i}>
                {subtitle.label} : {subtitle.value}
              </p>
            ))}
          </div>
          <div className={"lg:ml-auto mr-4 text-white w-48 "}>
            <div className={"flex flex-wrap"}>
              {alreadyRead || on ? null : (
                <div className={"bg-red-600 px-2 py-1 mb-1 mr-1 rounded"}>
                  Non lue
                </div>
              )}
              {alert.archived ? (
                <div className={"bg-gray-200 px-2 py-1 mb-1 mr-1 rounded"}>
                  Traitée
                </div>
              ) : null}
              {isPostponed ? (
                <div className={"bg-blue-500 px-2 py-1 mb-1 mr-1 rounded"}>
                  Reportée
                </div>
              ) : null}
              {isDelegated ? (
                <div className={"bg-green-800 px-2 py-1 mb-1 mr-1 rounded"}>
                  Déléguée
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={"lg:w-1/6 flex flex-col items-end md:ml-0"}>
          <div className={"text-right"}>
            <svg
              className={`transform fill-current text-green-600 ${
                on ? "rotate-180" : ""
              }`}
              width="20"
              height="12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.667 0L10 7.46 2.333 0 0 2.27 10 12l10-9.73L17.667 0z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className={`mt-2`}>
        <div className={`${on ? "block" : "hidden"} mt-2 cursor-default`}>
          {children}
          <div>
            <label className={`block text-black text-sm font-bold`}>
              Profils recevant l'alerte
            </label>
            <div
              className={`appearance-none bg-transparent w-full py-2 text-black leading-tight focus:outline-none focus:border-red`}
              style={{
                minHeight: "37px",
                backgroundColor: "#f7f7ff",
              }}
            >
              {join(map(alert.associatedPermissions, "label"))}
            </div>
          </div>
          {size(sortedAlertComments) ? (
            <div
                className={`appearance-none border-solid border-b border-black last:border-b-0`}
                style={{
                  minHeight: "37px",
                  backgroundColor: "#f7f7ff"
                }}
            >
              {map(sortedAlertComments, (comment) => {
                return (
                    <>
                      <p key={comment.id} className={"text-black text-sm"}>
                      <span className={"font-bold"}>
                        Commentaire de {comment.commentator.collaborator ?
                          comment.commentator.collaborator.firstname + ' ' + comment.commentator.collaborator.lastname :
                          'Inconnu'}:
                      </span>
                      </p>
                      <p>
                        <span>
                          {comment.comment}
                        </span>
                      </p>
                    </>
                )
              })}
            </div>
          ) : ("")}
          {isDelegated ? (
            <div>
              <label className={`block text-black text-sm font-bold`}>
                Délégué à
              </label>
              <div
                className={`appearance-none bg-transparent w-full py-2 text-black leading-tight focus:outline-none focus:border-red`}
                style={{
                  minHeight: "37px",
                  backgroundColor: "#f7f7ff",
                }}
              >
                {map(alert.delegatedUsers, (user) => (
                  <>{getFirstnameLastnameJob(user, "collaborator")}</>
                ))}
              </div>
            </div>
          ) : null}

          <div
            className={`${
              !canEdit || delayOn || delegateOn ? "hidden" : "flex"
            } mt-2 cursor-default`}
          >
            <div
              className={`${
                isArchive ? "hidden" : "flex"
              } btn btn--sm bg-green-900 rounded sm:ml-4 ml-1 flex flex-col items-center md:w-largeBtn w-40  h-16 justify-around py-2`}
              onClick={toggleDelegate}
            >
              <DeleguerIcon width={"26px"} height={"26px"} />
              <span
                className={
                  "text-white font-bold md:text-sm sm:text-md text-xxs"
                }
              >
                Déléguer
              </span>
            </div>
            <div
              className={`${
                isArchive ? "hidden" : "flex"
              } btn btn--sm bg-green-800 rounded w-half sm:ml-4 ml-1 flex flex-col items-center md:w-largeBtn w-40  h-16 justify-around py-2`}
              onClick={toggleDelay}
            >
              <ReporterIcon width={"26px"} height={"26px"} />
              <span
                className={
                  "text-white font-bold md:text-sm sm:text-md text-xxs"
                }
              >
                Reporter
              </span>
            </div>
            <div
                className={`${
                    isArchive ? "hidden" : "flex"
                } btn btn--sm bg-green-900 rounded sm:ml-4 ml-1 flex flex-col items-center md:w-largeBtn w-40  h-16 justify-around py-2`}
                onClick={toggleCommentate}
            >
              <CommenterIcon width={"26px"} height={"26px"} />
              <span
                  className={
                    "text-white font-bold md:text-sm sm:text-md text-xxs"
                  }
              >
                Commenter
              </span>
            </div>
            <div
              className={`btn btn--sm bg-green-600 rounded sm:ml-4 ml-1 flex flex-col items-center md:w-largeBtn w-40  h-16 justify-around py-2`}
              onClick={async () => {
                await updateAlert({
                  id: get(alert, "id"),
                  data: {
                    archived: !get(alert, "archived"),
                  },
                });
                toast.success("Alerte modifiée avec succès");
                setIsDisplayed(false);
              }}
            >
              <TraiterIcon width={"26px"} height={"26px"} />
              <span
                className={
                  "text-white font-bold md:text-sm sm:text-md text-xxs"
                }
              >
                {isArchive ? "Réactiver" : "Traiter"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={`${delegateOn ? "block" : "hidden"} mt-2`}>
        <Formik
          initialValues={{
            collaborators: [],
          }}
          onSubmit={async ({ collaborators }, actions) => {
            try {
              await updateAlert({
                id: get(alert, "id"),
                data: {
                  delegatedUsers: [get(collaborators, "value")],
                },
              });
              toast.success("Alerte déléguée avec succès");
              actions.resetForm();
            } catch (error) {
              map(get(error, "violations"), (e) => {
                actions.setFieldError(e.propertyPath, e.message);
              });
            } finally {
              toggleDelegate();
            }
          }}
          validationSchema={Yup.object().shape({
            collaborators: Yup.object()
              .test({
                test: ({ value, permissions }) => {
                  const listDelegatedUsers = map(alert.delegatedUsers, "@id");
                  const listAssociatedUsers = map(alert.associatedUsers, "@id");
                  const associatedPermissions = map(
                    alert.associatedPermissions,
                    "@id"
                  );
                  return (
                    !listDelegatedUsers.includes(value) &&
                    !listAssociatedUsers.includes(value) &&
                    isEmpty(intersection(associatedPermissions, permissions))
                  );
                },
                message:
                  "L’alerte ne peut être déléguée à un utilisateur qui a déjà reçu l’alerte.",
              })
              .test({
                test: () => {
                  const today = new Date();
                  today.setHours(0, 0, 0, 0);
                  return dateCompare(today, alert.alertDate);
                },
                message: "La date de la prochaine action est dépassée",
              }),
          })}
        >
          {({ values, isSubmitting, submitForm }) => (
            <Form>
              <Select
                name="collaborators"
                label="Déléguer à"
                options={userSelect}
                value={values.collaborators ? values.collaborators : null}
              />
              <div className="mt-4">
                <Button
                  className={`btn btn--sm align-top w-half ml-4`}
                  isSubmitting={isSubmitting}
                  isForm={true}
                  type="submit"
                  textLabel="Déléguer"
                />
                <div
                  className={`btn btn--sm bg-orange-500 hover:bg-orange-700 w-half ml-4`}
                  onClick={toggleDelegate}
                >
                  Annuler
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className={`${delayOn ? "block" : "hidden"} mt-2`}>
        <Formik
          initialValues={{
            delay: "",
          }}
          validationSchema={Yup.object().shape({
            reminderDate: Yup.date().required("Requis"),
          })}
          onSubmit={async ({ reminderDate }, actions) => {
            try {
              await updateAlert({
                id: get(alert, "id"),
                data: { reminderDate },
              });
              toast.success("Alerte reportée avec succès");
              actions.resetForm();
              toggleDelay();
            } catch (error) {
              map(get(error, "violations"), (e) => {
                actions.setFieldError(
                  e.propertyPath,
                  translateErrorMessage(e.message)
                );
              });
              if (get(error, "violations", false) === false) {
                toast.error("Erreur lors de l'enregistrement");
              }
            }
          }}
        >
          {({ values, isSubmitting, submitForm }) => (
            <Form>
              <DateTimePickerInput
                name="reminderDate"
                label="Nouvelle date de rappel"
                fullWidth={true}
                disabled={isSubmitting}
              />
              <div className="mt-4">
                <Button
                  className={`btn btn--sm align-top w-half ml-4`}
                  isSubmitting={isSubmitting}
                  isForm={true}
                  type="submit"
                  textLabel="Reporter"
                />
                <div
                  className={`btn btn--sm bg-orange-500 hover:bg-orange-700 w-half ml-4`}
                  onClick={toggleDelay}
                >
                  Annuler
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className={`${commentateOn ? "block" : "hidden"} mt-2`}>
        <Formik
            initialValues={{
              alert: get(alert, "@id"),
              commentator: userIri,
              comment: "",
            }}
            onSubmit={async ({ ...values }, actions) => {
              try {
                await createAlertComment({
                  alert: get(values, "alert"),
                  commentator: get(values, "commentator"),
                  comment: get(values, "comment")
                });
                toast.success("Commentaire envoyé avec succès");
                actions.resetForm();
              } catch (error) {
                map(get(error, "violations"), (e) => {
                  actions.setFieldError(e.propertyPath, e.message);
                });
              } finally {
                toggleCommentate();
              }
            }}
        >
          {({ values, isSubmitting, submitForm }) => (
              <Form>
                <Textarea
                    name="comment"
                    label="Votre commentaire"
                />
                <div className="mt-4">
                  <Button
                      className={`btn btn--sm align-top w-half ml-4`}
                      isSubmitting={isSubmitting}
                      isForm={true}
                      type="submit"
                      textLabel="Envoyer"
                  />
                  <div
                      className={`btn btn--sm bg-orange-500 hover:bg-orange-700 w-half ml-4`}
                      onClick={toggleCommentate}
                  >
                    Annuler
                  </div>
                </div>
              </Form>
          )}
        </Formik>
      </div>
    </Block>
  );
}

export default AlertItem;
