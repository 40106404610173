import { get } from "lodash-es";

export function getFirstnameLastname(object, path = null) {
  return get(object, path) || path === null
    ? `${get(object, `${path ? path + "." : ""}lastname`)} ${get(
        object,
        `${path ? path + "." : ""}firstname`
      )}`
    : "";
}

export function getFirstnameLastnameJob(object, path = null) {
  return get(object, path) || path === null
    ? `${get(object, `${path ? path + "." : ""}lastname`)} ${get(
        object,
        `${path ? path + "." : ""}firstname`
      )} ${
        get(object, `${path ? path + "." : ""}currentContract.job.label`, false)
          ? ` - ${get(
              object,
              `${path ? path + "." : ""}currentContract.job.label`
            )}`
          : ""
      }`
    : "";
}

export function getOnlyJob(object, path = null) {
  return get(object, path) || path === null
    ? `${
        get(object, `${path ? path + "." : ""}currentContract.job.label`, false)
          ? `${get(object, `${path ? path + "." : ""}currentContract.job.label`)}`
          : ""
      }`
    : "";
}
