import React, { useState } from "react";
import { get, map, size } from "lodash-es";
import Alert from "../components/Alert";
import ExpansionPanel from "../components/ExpansionPanel";
import { Helmet } from "react-helmet";
import { useDomain } from "../contexts/permissions";
import Spinner from "../components/Spinner";
import { useDesktop } from "../hooks/useDesktop";
import { HeaderBar } from "../components/HeaderBar";
import {useQueryAbsences} from "../api/absence";
import { getLocaleDateString, minutesToHoursString } from "../utils/date";
import {
  periodLabels,
} from "../utils/absenceStatus";
import { isCollaboratorAbsentToday } from "../utils/absence";


function InlineBLockContent({ children, className = "" }) {
  return (
    <div className={`px-4 flex items-center ${className}`}>{children}</div>
  );
}


function CollaboratorsAbsList() {
  
  const isDesktop = useDesktop();
  const currentDomainId = useDomain();
  
  const aujdhDeb = new Date();
  aujdhDeb.setHours(19,0,0,0);
  aujdhDeb.setDate(aujdhDeb.getDate() - 1);
  const aujdhFin = new Date();
  aujdhFin.setHours(19,0,0,0);
  
  
  const { data: absences } = useQueryAbsences(
    {
      "order[collaborator.lastname]": "asc",
      "domain": currentDomainId,
      "type.noPlanning": false,
      "endDate[after]": aujdhDeb,
      "startDate[before]": aujdhFin,
      "status": "validated",
    },
    false
  );
  
  
  if (size(absences) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="aucun collaborateur absent aujourd'hui" />
      </div>
    );
  }
  const gridTemplateColumn = "18% 16% 16% 16% 16% auto";
  
  return (
    <div className="relative">
      
      {isDesktop ? (
        <div>
        <div
          className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 sticky top-tab-header always-front`}
          style={{
            gridTemplateColumns: gridTemplateColumn,
          }}
        >
          <InlineBLockContent>
            Nom prénom
          </InlineBLockContent>
          <InlineBLockContent>
            Début absence
          </InlineBLockContent>
          <InlineBLockContent>
            Date de reprise
          </InlineBLockContent>
          <InlineBLockContent>
            Société/Agence
          </InlineBLockContent>
          <InlineBLockContent>
            Département
          </InlineBLockContent>
          <InlineBLockContent>
            Emploi
          </InlineBLockContent>
        </div>
        </div>
      ) : null}
   
        {isDesktop
          ? map(absences, (node) => (
                <SingleCollaborator
                  key={node.id}
                  node={node}
                  gridTemplateColumn={gridTemplateColumn}
                  ishidden={!isCollaboratorAbsentToday(node)}
                />
            ))
          : map(absences, (node) => (
              <CollaboratorMobileCard
                key={node.id}
                node={node}
                ishidden={!isCollaboratorAbsentToday(node)}
              />
            ))}

    </div>
  );
}

function CollaboratorMobileCard({
  node,
  ishidden
}) {

  return (
        
    <ExpansionPanel
      key={node.id}
      title={`${node.collaborator.firstname} ${node.collaborator.lastname}`}
      subtitle={get(node, "collaborator.currentContract.job.label")}
      className={`${ ishidden ? "hidden" : "" }`}
    >
    <div>
            <p>
              {`Absent(e) à partir du : ${ node.startDate ? new Date( node.startDate ).toLocaleDateString() : "" }
                 ${ (get(node, "startHour", null) ? " à " + minutesToHoursString(get(node, "startHour")) : "")}
                 ${ (get(node, "startPeriod", null) ? " " + periodLabels[get(node, "startPeriod")] : "")}
              ${ node.endDate ? " jusqu'au : " + new Date(node.endDate).toLocaleDateString() : ""}
                 ${ (get(node, "endHour", null) ? " à " + minutesToHoursString(get(node, "endHour")) : "") }
                 ${ (get(node, "endPeriod", null) ? " " + periodLabels[get(node, "endPeriod")] : "") }
              `}
            </p>
    </div>
    </ExpansionPanel>

  );
}

function SingleCollaborator({
  node,
  gridTemplateColumn,
  ishidden
}) {
 
 console.log ("ishidden=" + ishidden);
  
    return ( 
    <div
      className={`grid divide-x divide-gray-100 bg-white hover:bg-purple-50 shadow mb-2 py-2 cursor-pointer ${
                ishidden ? "hidden" : ""
              }`}
      style={{
        gridTemplateColumns: gridTemplateColumn,
      }}
    >
      <InlineBLockContent className="">
        {node.collaborator.lastname} {node.collaborator.firstname}
      </InlineBLockContent>
      <InlineBLockContent className="">
        <div className="">
        {getLocaleDateString(node, "startDate") + (get(node, "startHour", null) ? " à " + minutesToHoursString(get(node, "startHour")) : "") + (get(node, "startPeriod", null) ? " " + periodLabels[get(node, "startPeriod")] : "")}
        </div>
      </InlineBLockContent>
      <InlineBLockContent className="">
        <div className="">
        {getLocaleDateString(node, "endDate") + (get(node, "endHour", null) ? " à " + minutesToHoursString(get(node, "endHour")) : "") + (get(node, "endPeriod", null) ? " " + periodLabels[get(node, "endPeriod")] : "")}
        </div>
      </InlineBLockContent>
      <InlineBLockContent className="">
        <div className={"flex flex-col"}>
          {map(node.collaborator.companies, (company, key) => (
              <div key={key} className="mr-2">
                {company.label.toUpperCase()}
              </div>
          ))}
          {map(node.collaborator.agencies, (agency, key) => (
            <div key={key} className="mr-2">
              {agency.label.toLowerCase()}
            </div>
          ))}
        </div>
      </InlineBLockContent>
      <InlineBLockContent className="">
      <div className={"flex flex-col"}>
        {map(node.collaborator.departments, (department, key) => (
            <div key={key} className="mr-2">
              {department.label.toLowerCase()}
            </div>
        ))}
        </div>
      </InlineBLockContent>
      <InlineBLockContent className="">
        {get(node, "collaborator.currentContract.job.label")}
      </InlineBLockContent>
 
    </div>
  );
}

export default function CollaboratorsAbsents() {
    const isDesktop = useDesktop();
    
    return (
    <div>
      <Helmet>
        <title>Absents</title>
      </Helmet>
      <HeaderBar
        sticky={true} smaller={false}
        title={
          <>
            {isDesktop ? "Collaborateurs absents actuellement" : "Absents"}
          </>
        }
        >
      </HeaderBar>
      <div>
        <div className="px-8 mt-8 mb-4">
              <React.Suspense fallback={<Spinner />}>
                <CollaboratorsAbsList />
              </React.Suspense>
        </div>
      </div>
    </div>
    );
}


