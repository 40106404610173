import {
  PermissionChecker,
  useIsSilae,
  usePermissionsChecker,
  useUseExternalDocumentManagement,
} from "../../contexts/permissions";
import { Form, Formik } from "formik";
import { useUpdatePersonalInformation } from "../../api/personalInformation";
import { useCreateOrUpdateHandicap } from "../../api/handicap";
import BankingInformations from "../../forms/BankingInformations";
import { CollaboratorContext } from "../../contexts/collaborator";
import ExpansionPanel from "../../components/ExpansionPanel";
import FamilySituation from "../../forms/FamilySituation";
import HealthAssurance from "../../forms/HealthAssurance";
import PersonalCoordinate from "../../forms/PersonalCoordinate";
import PersonalInfos from "../../forms/PersonalInfos";
import Handicap from "../../forms/Handicap";
import React from "react";
import { useDesktop } from "../../hooks/useDesktop";
import { get, map } from "lodash-es";
import PersonalPostalAddressForm from "../../forms/PersonalPostalAddressForm";
import Button from "../../components/Button";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useCreateOrUpdateIdCard } from "../../api/idCard";
import Alert from "../../components/Alert";
import EmergencyContact from "../../forms/EmergencyContact";
import { dateCompare } from "../../regex/date";
import { toFlatPropertyMap } from "../../utils/flattenObject";

const isset = (val) => {
  return val !== null && val !== "" && val !== undefined;
};
const hasDefinedValue = (obj) => {
  for (const key in obj) {
    if (isset(obj[key])) {
      return true;
    }
  }
  return false;
};

function Personnel() {
  const isDesktop = useDesktop();
  const isSilae = useIsSilae();
  const useExternalDocumentManagement = useUseExternalDocumentManagement();
  const [createIdCard, { errorCard }] = useCreateOrUpdateIdCard();
  const [updatePersonalInformation, { error }] = useUpdatePersonalInformation();
  const { personalInformation } = React.useContext(CollaboratorContext);
  const permissionsView = [
    "kdix.actions.personal_information.view",
    "kdix.actions.personal_information.view.agency",
    "kdix.actions.personal_information.view.department",
    "kdix.actions.personal_information.view.service",
    "kdix.actions.personal_information.view.own",
  ];
  const permissionsEdit = [
    "kdix.actions.personal_information.edit",
    "kdix.actions.personal_information.edit.agency",
    "kdix.actions.personal_information.edit.department",
    "kdix.actions.personal_information.edit.service",
    "kdix.actions.personal_information.edit.own",
  ];
  const canEdit = usePermissionsChecker({ permissions: permissionsEdit });
  const validationCard = useExternalDocumentManagement
    ? {
        idCard: Yup.mixed().test(
          "fileFormat",
          "Formats autorisés: pdf, jpg, png",
          (value) => {
            const authorized = ["application/pdf", "image/jpeg", "image/png"];
            return !value || authorized.includes(value.type);
          }
        ),
      }
    : {};
  const cardValue = useExternalDocumentManagement ? {} : { idCard: null };
  const residencePermitDocumentValue = useExternalDocumentManagement ? {} : { residencePermitDocument: null };
  const [
    updateHandicap,
    { error: handicapError },
  ] = useCreateOrUpdateHandicap();

  const silaeValidation = isSilae
    ? {
      socialSecurityNumber: Yup.string().required("Requis"),
    }
    : {
        address: Yup.object().shape({
          street: Yup.string().required("Requis"),
          postCode: Yup.string().required("Requis"),
          city: Yup.string().required("Requis"),
          country: Yup.string().required("Requis"),
        }),
        socialSecurityNumber: Yup.string().required("Requis"),
        birthCountry: Yup.string().when("healthInsuranceNumber", {
          is: isset,
          then: Yup.string().required("Requis"),
        }),

        emergencyContact: Yup.object().shape({
          firstname: Yup.string().test("custom-required", "Requis", function (
            value
          ) {
            if (
              (isset(this.parent.lastname) &&
                isset(this.parent.firstname) &&
                isset(this.parent.phoneNumber)) ||
              isset(value)
            ) {
              return true;
            }
            return !hasDefinedValue(toFlatPropertyMap(this.parent));
          }),
          lastname: Yup.string().test("custom-required", "Requis", function (
            value
          ) {
            if (
              (isset(this.parent.lastname) &&
                isset(this.parent.firstname) &&
                isset(this.parent.phoneNumber)) ||
              isset(value)
            ) {
              return true;
            }
            return !hasDefinedValue(toFlatPropertyMap(this.parent));
          }),
          phoneNumber: Yup.string().test("custom-required", "Requis", function (
            value
          ) {
            if (
              (isset(this.parent.lastname) &&
                isset(this.parent.firstname) &&
                isset(this.parent.phoneNumber)) ||
              isset(value)
            ) {
              return true;
            }
            return !hasDefinedValue(toFlatPropertyMap(this.parent));
          }),
        }),
      };

  const sharedValidation = {
    privateEmail: Yup.string().email("Format non valide").nullable(),
    privateMobileNumber: Yup.string().max(30, "Le téléphone doit faire moins de 30 caractères"),
    privateTelephoneNumber: Yup.string().max(30, "Le téléphone doit faire moins de 30 caractères"),
    partnerBirthDate: Yup.date().nullable(),
    children: Yup.array().of(
      Yup.object().shape({
        firstname: Yup.string().required("Requis"),
        lastname: Yup.string().required("Requis"),
        birthDate: Yup.date()
          .nullable()
          .test(
            "date-match",
            "Doit être antérieure à la date du jour",
            function (value) {
              return dateCompare(value, new Date());
            }
          ),
      })
    ),
    affilitationDate: Yup.date()
      .nullable()
      .when("healthInsuranceNumber", {
        is: isset,
        then: Yup.date().required("Requis"),
      }),
    birthCountry: Yup.string().when("healthInsuranceNumber", {
      is: isset,
      then: Yup.string().required("Requis"),
    }),
    residencePermitExpirationDate: Yup.date().nullable(),
    ...validationCard,
    beneficiaries: Yup.array().of(
      Yup.object().shape({
        firstname: Yup.string().required("Requis"),
        lastname: Yup.string().required("Requis"),
        birthDate: Yup.date()
          .nullable()
          .required("Requis")
          .test(
            "date-match",
            "Doit être antérieure à la date du jour",
            function (value) {
              return dateCompare(value, new Date());
            }
          ),
        affiliationDate: Yup.date()
          .nullable()
          .required("Requis")
          .test(
            "date-match",
            "Doit être postérieure à la date de naissance",
            function (value) {
              return dateCompare(this.parent.birthDate, value);
            }
          ),
      })
    ),
    emergencyContact: Yup.object().shape({
      status: Yup.string().nullable(),
      address: Yup.object().shape({
        postCode: Yup.string().nullable(),
        city: Yup.string().nullable(),
      }),
    }),
    handicap: Yup.object().shape({
      comment: Yup.string().typeError("Doit être du texte"),
      rate: Yup.number().nullable().typeError("Doit être un nombre entier"),
      dateRecognition: Yup.date().nullable(),
      delayInvalidity: Yup.date()
        .nullable()
        .test(
          "date-match",
          "Doit être postérieure à la date de reconnaissance",
          function (value) {
            return dateCompare(this.parent.dateRecognition, value);
          }
        ),
    }),
  };

  const validation = Yup.object().shape({
    ...sharedValidation,
    ...silaeValidation,
  });
  const personalInformations = personalInformation.data;
  return (
    <PermissionChecker permissions={permissionsView}>
      <Formik
        enableReinitialize={true}
        validationSchema={validation}
        initialValues={{
          privateTelephoneNumber: get(
            personalInformations,
            "privateTelephoneNumber",
            ""
          ),
          privateMobileNumber: get(
            personalInformations,
            "privateMobileNumber",
            ""
          ),
          privateEmail: get(personalInformations, "privateEmail", ""),
		  privateEmailAlert: get(personalInformations, "privateEmailAlert", false),
          address: {
            street: get(personalInformations, "address.street", ""),
            complement:
              personalInformations &&
              personalInformations.address &&
              personalInformations.address.complement
                ? personalInformations.address.complement
                : "",
            postCode: get(personalInformations, "address.postCode", ""),
            city: get(personalInformations, "address.city", ""),
            country: get(personalInformations, "address.country", ""),
          },

          familySituation: {
            value: get(personalInformations, "familySituation.@id", ""),
            label: get(personalInformations, "familySituation.label", ""),
          },
          partnerFirstName: get(personalInformations, "partnerFirstName", ""),
          partnerLastName: get(personalInformations, "partnerLastName", ""),
          partnerBirthDate: get(personalInformations, "partnerBirthDate")
            ? new Date(get(personalInformations, "partnerBirthDate"))
            : null,
          children:
            map(personalInformations.children, (child) => ({
              ...child,
              birthDate: get(child, "birthDate")
                ? new Date(get(child, "birthDate"))
                : null,
            })) || [],
          bankName: get(personalInformations, "bankName", ""),
          iban: get(personalInformations, "iban", ""),
          bic: get(personalInformations, "bic", ""),
          beneficiaries:
            map(personalInformations.beneficiaries, (beneficiary) => ({
              ...beneficiary,
              birthDate: get(beneficiary, "birthDate")
                ? new Date(get(beneficiary, "birthDate"))
                : null,
              affiliationDate: get(beneficiary, "affiliationDate")
                ? new Date(get(beneficiary, "affiliationDate"))
                : null,
            })) || [],
          birthPlace: get(personalInformations, "birthPlace", ""),
          birthCountry: {
            label: get(personalInformations, "birthCountry.name", ""),
            value: get(personalInformations, "birthCountry.@id", ""),
          },
          nationalities: map(
            get(personalInformations, "nationalities"),
            (node) => ({
              label: node.name,
              value: get(node, "@id"),
            }),
            []
          ),
          socialSecurityNumber: get(
            personalInformations,
            "socialSecurityNumber",
            ""
          ),
          healthInsuranceNumber: get(
            personalInformations,
            "healthInsuranceNumber",
            ""
          ),
          affilitationDate: get(personalInformations, "affilitationDate")
            ? new Date(get(personalInformations, "affilitationDate"))
            : "",

          residencePermit: get(personalInformations, "residencePermit", false),
          residentPermitNumber: get(
            personalInformations,
            "residentPermitNumber",
            ""
          ),
          residencePermitExpirationDate: get(
            personalInformations,
            "residencePermitExpirationDate"
          )
            ? new Date(
                get(personalInformations, "residencePermitExpirationDate")
              )
            : "",
          ...cardValue,
          ...residencePermitDocumentValue,
          emergencyContact: {
            firstname: get(
              personalInformations,
              "emergencyContact.firstname",
              ""
            ),
            lastname: get(
              personalInformations,
              "emergencyContact.lastname",
              ""
            ),
            phoneNumber: get(
              personalInformations,
              "emergencyContact.phoneNumber",
              ""
            ),
            status: get(personalInformations, "emergencyContact.status", ""),
            address: {
              street: get(
                personalInformations,
                "emergencyContact.address.street",
                ""
              ),
              complement: get(
                personalInformations,
                "emergencyContact.address.complement",
                ""
              ),
              postCode: get(
                personalInformations,
                "emergencyContact.address.postCode",
                ""
              ),
              city: get(
                personalInformations,
                "emergencyContact.address.city",
                ""
              ),
            },
          },
          handicap: {
            comment: get(personalInformations, "handicap.comment", ""),
            rate: get(personalInformations, "handicap.rate", ""),
            dateRecognition: get(
              personalInformations,
              "handicap.dateRecognition"
            )
              ? new Date(get(personalInformations, "handicap.dateRecognition"))
              : null,
            delayInvalidity: get(
              personalInformations,
              "handicap.delayInvalidity"
            )
              ? new Date(get(personalInformations, "handicap.delayInvalidity"))
              : null,
          },
        }}
        onSubmit={async ({ handicap, ...values }, actions) => {
          try {
            const prepareValues = {
              ...values,
              familySituation: values.familySituation.value || null,
              partnerBirthDate: values.partnerBirthDate
                ? values.partnerBirthDate
                : null,
              children: map(values.children, (child) => ({
                ...child,
                birthDate: child.birthDate ? child.birthDate : null,
              })),
              nationalities: values.nationalities.map((a) => a.value),
              birthCountry: values.birthCountry.value || null,
              affilitationDate: values.affilitationDate
                ? values.affilitationDate
                : null,
              residencePermitExpirationDate: values.residencePermitExpirationDate
                ? values.residencePermitExpirationDate
                : null,
              beneficiaries: map(values.beneficiaries, (beneficiary) => ({
                ...beneficiary,
                birthDate: beneficiary.birthDate ? beneficiary.birthDate : null,
                affiliationDate: beneficiary.affiliationDate
                  ? beneficiary.affiliationDate
                  : null,
              })),
            };
            
            delete prepareValues.idCard;

            await updatePersonalInformation({
              id: get(personalInformations, "id"),
              data: prepareValues,
            });

            //gestion des handicap
            await updateHandicap({
              id: get(personalInformations, "handicap.id"),
              data: {
                personalInformation: get(personalInformations, "@id"),
                rate: parseInt(handicap.rate, 10),
                comment: handicap.comment,
                dateRecognition: handicap.dateRecognition
                  ? handicap.dateRecognition
                  : null,
                delayInvalidity: handicap.delayInvalidity
                  ? handicap.delayInvalidity
                  : null,
                test: handicap.delayInvalidity,
              },
            });
            if (values.idCard) {
              await createIdCard({
                file: values.idCard,
                personalInformationId: personalInformations.id,
              });
            }
            toast.success("Mise à jour effectuée avec succès");
          } catch (error) {
            map(get(error, "violations"), (e, idx) => {
              actions.setFieldError(e.propertyPath, e.message);
              if (idx === 0) {
                document
                  .getElementsByName(e.propertyPath)[0]
                  .scrollIntoView({ block: "center", behavior: "smooth" });
              }
            });
          }
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={"flex flex-wrap"}>
              {error && get(error, "violations.length", []).length === 0 ? (
                <div className="my-2 flex-grow mr-4">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}
              {errorCard &&
              get(errorCard, "violations.length", []).length === 0 ? (
                <div className="my-2 flex-grow mr-4">
                  <Alert
                    type="error"
                    message={get(errorCard, "title")}
                    details={get(errorCard, "description")}
                  />
                </div>
              ) : null}

              {handicapError &&
              get(handicapError, "violations.length", []).length === 0 ? (
                <div className="my-2 flex-grow mr-4">
                  <Alert
                    type="error"
                    message={get(handicapError, "title")}
                    details={get(handicapError, "description")}
                  />
                </div>
              ) : null}
              {canEdit && (
                <Button
                  className={`ml-auto btn my-3 block`}
                  isSubmitting={isSubmitting}
                  type="submit"
                  isForm={true}
                >
                  Enregistrer
                </Button>
              )}
            </div>
            <div
              className={`xl:grid xl:grid-cols-3 grid-flow-row-dense grid-rows-1 xl:gap-2`}
            >
              <div>
                <ExpansionPanel
                  title="Coordonnées personnelles"
                  open={isDesktop}
                >
                  <PersonalCoordinate />
                  <PersonalPostalAddressForm />
                </ExpansionPanel>
                {isDesktop ? (
                  <>
                    <ExpansionPanel
                      title="Coordonnées bancaires"
                      open={isDesktop}
                    >
                      <BankingInformations />
                    </ExpansionPanel>
                    <ExpansionPanel
                      title="Ayant(s) droit(s) mutuelle"
                      open={isDesktop}
                    >
                      <HealthAssurance />
                    </ExpansionPanel>
                  </>
                ) : null}
              </div>
              <div>
                <ExpansionPanel
                  title="Informations personnelles"
                  open={isDesktop}
                >
                  <PersonalInfos
                    personalInformations={personalInformation.data}
                  />
                </ExpansionPanel>
                {isDesktop ? (
                  <ExpansionPanel title="Handicap" open={isDesktop}>
                    <Handicap
                      personalInformations={personalInformation.data}
                      readOnly={!canEdit}
                    />
                  </ExpansionPanel>
                ) : null}
              </div>
              <div>
                <ExpansionPanel
                  title="Contact en cas d'urgence"
                  open={isDesktop}
                  className="EmergencyContact"
                >
                  <EmergencyContact />
                </ExpansionPanel>
                {!isDesktop ? (
                  <>
                    <ExpansionPanel
                      title="Coordonnées bancaires"
                      open={isDesktop}
                    >
                      <BankingInformations />
                    </ExpansionPanel>

                    <ExpansionPanel title="Handicap" open={isDesktop}>
                      <Handicap
                        readOnly={!canEdit}
                        personalInformations={personalInformation.data}
                      />
                    </ExpansionPanel>
                  </>
                ) : null}

                <ExpansionPanel title="Situation Familiale" open={isDesktop}>
                  <FamilySituation
                    personalInformations={personalInformation.data}
                  />
                </ExpansionPanel>
                {!isDesktop ? (
                  <ExpansionPanel
                    title="Ayant(s) droit(s) mutuelle"
                    open={isDesktop}
                  >
                    <HealthAssurance />
                  </ExpansionPanel>
                ) : null}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </PermissionChecker>
  );
}

export default Personnel;
