import React from "react";
import { Form, Formik } from "formik";
import {useDomain} from "../contexts/permissions";
import {useCreateOrUpdateAlertSilencePeriod, useQueryAlertSilencePeriods} from "../api/alertSilencePeriod";
import { get, map } from "lodash-es";
import Button from "./Button";
import {DateTimePickerInput} from "./Input";
import Checkbox from "./Checkbox";


function AlertSilencePeriodAdmin() {
    const domainId = useDomain();
    const { data: alertSilencePeriods } = useQueryAlertSilencePeriods({
        domain: domainId
    });
    const currentSilencePeriod = alertSilencePeriods.length > 0 ? alertSilencePeriods[0] : null;
    const [createOrUpdate] = useCreateOrUpdateAlertSilencePeriod();

    return (
        <>
            <div className={`px-8 mb-16 mt-16 flex justify-center`}>
                <div className="md:w-2/3 w-full">
                    <div
                        className="grid gap-4 items-center py-4 text-lg text-gray-600 text-opacity-75 uppercase"
                        style={{
                            gridTemplateColumns: `auto 12rem`,
                        }}
                    >
                        <span>Suspendre l'envoi des mails d'alertes et notifications pendant une période</span>
                    </div>
                    <div>
                        <Formik
                            initialValues={{
                                startDate: get(currentSilencePeriod, "startDate") ? new Date(get(currentSilencePeriod, "startDate")) : "",
                                endDate: get(currentSilencePeriod, "endDate") ? new Date(get(currentSilencePeriod, "endDate")) : "",
                                active: get(currentSilencePeriod, "active", false),
                            }}
                            onSubmit={async (values, actions) => {
                                try {
                                    const response = await createOrUpdate({
                                        id: get(currentSilencePeriod, "id", null),
                                        data: {
                                            ...values,
                                        },
                                    });
                                    actions.setSubmitting(false);
                                } catch (error) {
                                    map(get(error, "violations"), (e) => {
                                        actions.setFieldError(e.propertyPath, e.message);
                                    });
                                    actions.setSubmitting(false);
                                }
                            }}
                        >
                            {({ values, isSubmitting, submitForm }) => (
                                <Form>
                                    <div className="grid gap-4 items-center py-4"
                                         style={{
                                             gridTemplateColumns: `15rem 15rem 3rem 15rem`,
                                         }}
                                    >
                                        <DateTimePickerInput
                                            name="startDate"
                                            label="Date de début"
                                            disabled={isSubmitting}
                                        />
                                        <DateTimePickerInput
                                            name="endDate"
                                            label="Date de fin"
                                            disabled={isSubmitting}
                                        />
                                        <Checkbox
                                            readOnly={false}
                                            type="checkbox"
                                            name="active"
                                            label="Actif"
                                        />
                                        <Button
                                            className={`btn btn--sm align-top w-half ml-4`}
                                            isSubmitting={isSubmitting}
                                            isForm={true}
                                            type="submit"
                                            textLabel="Enregistrer"
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AlertSilencePeriodAdmin;
