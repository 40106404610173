import React from "react";
import {
  useQueryDomains,
  useQueryDomainApiSilae,
  useSynchronizeCollaboratorsRest,
} from "../api/domain";
import { filter, find, get, map } from "lodash-es";
import { Form, Formik } from "formik";
import Block from "../components/Block";
import Alert from "../components/Alert";
import ExpansionPanel from "../components/ExpansionPanel";
import { toast } from "react-toastify";
import Button from "../components/Button";
import { useDeleteApiSilae, useCreateOrUpdateApiSilae } from "../api/apiSilae";
import Input from "../components/Input";
import EditButton from "../components/HistoryItem/EditButton";
import Modal from "../components/Modal";
import * as Yup from "yup";
import Checkbox from "../components/Checkbox";
import Select from "../components/Select";
import {
  useCreateOrUpdateMapping,
  useDeleteMapping,
  useQueryMappingByDomain,
} from "../api/mapping";
import {
  MAPPING_KEY_COEFFICIENT,
  MAPPING_KEY_ECHELON,
  MAPPING_KEY_LEVEL,
  MAPPING_LABEL_COEFFICIENT,
  MAPPING_LABEL_ECHELON,
  MAPPING_LABEL_LEVEL,
  mappingValues,
} from "../utils/mapping";

function getInitialSelect(mappingObject) {
  return {
    value: {
      value: mappingObject?.value ?? "",
      label: mappingObject?.value ?? "Sélectionner...",
    },
    id: mappingObject?.id ?? null,
  };
}

function SilaeParams({ domain }) {
  const [updateApiSilae] = useCreateOrUpdateApiSilae();
  const [synchronizeCollaboratorsRest] = useSynchronizeCollaboratorsRest();
  const [modalDelete, setModalDelete] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isSynchronizing, setIsSynchronizing] = React.useState(false);
  let { data, error } = useQueryDomainApiSilae(domain.id);
  const isSoapApiSilae = typeof data.SILAE_WSDL !== "undefined";
  const isRestApiSilae = typeof data.restClientId !== "undefined";
  const readOnly = !!domain && !isEditing;
  const mappings = useQueryMappingByDomain(domain["@id"]);
  const [createOrUpdateMapping] = useCreateOrUpdateMapping();
  const [deleteMapping] = useDeleteMapping();
  const mappingCoefficient = find(mappings, { key: MAPPING_KEY_COEFFICIENT });
  const mappingLevel = find(mappings, { key: MAPPING_KEY_LEVEL });
  const mappingEchelon = find(mappings, { key: MAPPING_KEY_ECHELON });
  const optionSem = map(mappingValues, (mappingValue) => ({
    label: mappingValue,
    value: mappingValue,
  }));
  if (error) {
    return (
      <Alert
        type="error"
        message={get(error, "title", "Erreur")}
        details={get(
          error,
          "description",
          `Récupération des paramètres de l'API impossible`
        )}
      />
    );
  }

  return (
    <div>
      <Formik
        initialValues={{
          "ACTIVE": data.ACTIVE ?? false,
          "SILAE_WSDL": data.SILAE_WSDL ?? "",
          "SWS_LOGIN": data.SWS_LOGIN ?? "",
          "SWS_PWD": data.SWS_PWD ?? "",
          "USR_LOGIN": data.USR_LOGIN ?? "",
          "USR_PWD": data.USR_PWD ?? "",
          "EDI_LOGIN": data.EDI_LOGIN ?? "",
          "EDI_PWD": data.EDI_PWD ?? "",
          "restClientId": data.restClientId ?? "",
          "restClientSecret": data.restClientSecret ?? "",
          "restSubscriptionKey": data.restSubscriptionKey ?? "",
          "restFolderName": data.restFolderName ?? "",
          [MAPPING_KEY_COEFFICIENT]: getInitialSelect(mappingCoefficient),
          [MAPPING_KEY_ECHELON]: getInitialSelect(mappingEchelon),
          [MAPPING_KEY_LEVEL]: getInitialSelect(mappingLevel),
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          SILAE_WSDL: Yup.string().required("Requis"),
        })}
        onSubmit={async (values, actions) => {
          try {
            await updateApiSilae({
              domainId: domain.id,
              data: {
                "active": values.ACTIVE,
                "silaeWsdl": values.SILAE_WSDL,
                "swsLogin": values.SWS_LOGIN,
                "swsPwd": values.SWS_PWD,
                "usrLogin": values.USR_LOGIN,
                "usrPwd": values.USR_PWD,
                "editLogin": values.EDI_LOGIN,
                "editPwd": values.EDI_PWD,
                "restClientId": values.restClientId,
                "restClientSecret": values.restClientSecret,
                "restSubscriptionKey": values.restSubscriptionKey,
                "restFolderName": values.restFolderName,
                "id": data.id,
                "domain": domain["@id"],
              },
            });

            for (const key of [
              MAPPING_KEY_LEVEL,
              MAPPING_KEY_COEFFICIENT,
              MAPPING_KEY_ECHELON,
            ]) {
              if (values[key]?.value?.value) {
                await createOrUpdateMapping({
                  id: values[key].id,
                  data: {
                    key: key,
                    value: values[key]?.value?.value ?? null,
                    domain: domain["@id"],
                  },
                });
              } else if (values[key].id) {
                await deleteMapping(values[key].id);
              }
            }

            toast.success("L'API Silae a bien été créée/modifiée");
            // actions.resetForm();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values, setSubmitting, resetForm }) => {
          return (
            <Form>
              {(isSoapApiSilae || isRestApiSilae ||isEditing) && (
                <>
                  <div className="px-4">
                    <Checkbox
                      readOnly={readOnly}
                      type="checkbox"
                      name="ACTIVE"
                      label="Synchronisation automatique avec SILAE (tlj : 1h et 13h)"
                      className={"mb-8"}
                    />
                  </div>
                  <div className="px-4 font-bold my-6">
                    Informations API Silae SOAP (obsolète)
                  </div>
                  <div className="px-4">
                    <Input
                      readOnly={readOnly}
                      type="text"
                      name="SILAE_WSDL"
                      label="Adresse de connexion SILAE"
                    />
                  </div>
                  <div className="mb-4 xl:grid xl:grid-cols-3 gap-12">
                    <div className="px-4">
                      <Input
                        readOnly={readOnly}
                        type="text"
                        name="SWS_LOGIN"
                        label="Login SILAE"
                      />
                      <Input
                        readOnly={readOnly}
                        type="text"
                        name="SWS_PWD"
                        label="Mot de passe SILAE"
                      />
                    </div>
                    <div className="px-4">
                      <Input
                        readOnly={readOnly}
                        type="text"
                        name="USR_LOGIN"
                        label="Login utilisateur"
                      />
                      <Input
                        readOnly={readOnly}
                        type="text"
                        name="USR_PWD"
                        label="Mot de passe utilisateur"
                      />
                    </div>
                    <div className="px-4">
                      <Input
                        readOnly={readOnly}
                        type="text"
                        name="EDI_LOGIN"
                        label="Login éditeur"
                      />
                      <Input
                        readOnly={readOnly}
                        type="text"
                        name="EDI_PWD"
                        label="Mot de passe éditeur"
                      />
                    </div>
                  </div>
                  <div className="px-4 font-bold my-6">
                    Informations API Silae REST
                  </div>
                  <div className="mb-4 xl:grid xl:grid-cols-2 gap-12">
                    <div className="px-4">
                      <Input
                          readOnly={readOnly}
                          type="text"
                          name="restClientId"
                          label="Client ID SILAE REST"
                      />
                      <Input
                          readOnly={readOnly}
                          type="text"
                          name="restClientSecret"
                          label="Client Secret SILAE REST"
                      />
                    </div>
                    <div className="px-4">
                      <Input
                          readOnly={readOnly}
                          type="text"
                          name="restSubscriptionKey"
                          label="Clé de souscription"
                      />
                      <Input
                          readOnly={readOnly}
                          type="text"
                          name="restFolderName"
                          label="Nom du dossier"
                      />
                    </div>
                  </div>
                  <div className="px-4 font-bold my-6">
                    Lien entre les champs K-Dix et les variables SILAE
                  </div>
                  <div className="mb-4 xl:grid xl:grid-cols-3 gap-12 px-4">
                    <Select
                      name={`${MAPPING_KEY_LEVEL}.value`}
                      label={MAPPING_LABEL_LEVEL}
                      options={filterOptionMapping(optionSem, values)}
                      isClearable
                      isDisabled={readOnly}
                    />
                    <Select
                      name={`${MAPPING_KEY_ECHELON}.value`}
                      label={MAPPING_LABEL_ECHELON}
                      options={filterOptionMapping(optionSem, values)}
                      isClearable
                      isDisabled={readOnly}
                    />
                    <Select
                      name={`${MAPPING_KEY_COEFFICIENT}.value`}
                      label={MAPPING_LABEL_COEFFICIENT}
                      options={filterOptionMapping(optionSem, values)}
                      isClearable
                      isDisabled={readOnly}
                    />
                  </div>
                </>
              )}
              <div className="flex mt-4">
                <EditButton
                  onDelete={
                    isSoapApiSilae
                      ? async () => {
                          setIsEditing(false);
                          setModalDelete({
                            domain: domain,
                            data: data,
                          });
                        }
                      : false
                  }
                  isSubmitting={isSubmitting}
                  isEditing={isEditing}
                  onCancel={() => {
                    resetForm();
                    setIsEditing(!isEditing);
                  }}
                  onClick={() => {
                    setIsEditing(!isEditing);
                  }}
                />
                {isRestApiSilae && (
                    <>
                      <Button
                          className="ml-4 btn btn--sm btn--outline--reversed"
                          type="button"
                          isSubmitting={isSynchronizing}
                          onClick={async () => {
                            setIsSynchronizing(true);
                            toast.info(
                                `Synchronisation des collaborateurs en cours sur le domaine "${domain.subDomainName}"`
                            );
                            await synchronizeCollaboratorsRest({
                              domainId: domain.id,
                            });
                            setIsSynchronizing(false);
                          }}
                      >
                        Synchroniser (REST)
                      </Button>
                    </>
                )}
              </div>
              <Modal
                title={`Supprimer l'Api du domaine ${domain.subDomainName}`}
                handleClose={(e) => {
                  e.stopPropagation();
                }}
                isOpen={modalDelete !== false}
                onRequestClose={() => setModalDelete(false)}
              >
                <div className="bg-white">
                  <DeleteApiButton
                    domain={domain}
                    data={data}
                    setModalDelete={setModalDelete}
                  />
                </div>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

function filterOptionMapping(optionSem, values) {
  return filter(optionSem, (o) => {
    return (
      values[MAPPING_KEY_ECHELON]?.value?.value !== o.value &&
      values[MAPPING_KEY_COEFFICIENT]?.value?.value !== o.value &&
      values[MAPPING_KEY_LEVEL]?.value?.value !== o.value
    );
  });
}

function DeleteApiButton({ domain, data, setModalDelete }) {
  const [deleteApiSilae] = useDeleteApiSilae();

  return (
    <>
      <div className="flex justify-center">
        <Button
          className={`btn mt-6 block`}
          readOnly={false}
          onClick={async () => {
            await deleteApiSilae({
              id: domain.id,
              data: data,
            });
            setModalDelete(false);
            toast.success(`L'Api de ${domain.subDomainName} a été supprimée`);
          }}
          isForm={true}
          type="submit"
          textLabel={"Supprimer l'Api " + domain.subDomainName}
        />
      </div>
    </>
  );
}

export default function ApiSilaeForm() {
  const { data, error } = useQueryDomains({
    "order[subDomainName]": "asc",
  });
  if (error) {
    return (
      <Alert
        type="error"
        message={get(error, "title", "Erreur")}
        details={get(
          error,
          "description",
          `Récupération des domaines impossible`
        )}
      />
    );
  }

  return (
    <>
      <div className="p-8 pb-24">
        <Block>
          <div className="font-bold text-xl leading-tight mb-8">
            Administration importation
          </div>
          {map(data, (domain, index) => {
            return (
              <ExpansionPanel
                key={index}
                title={domain.subDomainName}
                customStyle={"default"}
              >
                <SilaeParams domain={domain} />
              </ExpansionPanel>
            );
          })}
        </Block>
      </div>
    </>
  );
}
