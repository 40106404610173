import React from "react";
import { useCreateOrUpdateDomain } from "../api/domain";
import { Form, Formik } from "formik";
import { get, map, noop } from "lodash-es";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Input from "../components/Input";
import Checkbox from "../components/Checkbox";
import Alert from "../components/Alert";
import EditButton from "../components/HistoryItem/EditButton";
import Button from "../components/Button";
import { useDomain } from "../contexts/permissions";
import { useQuerySynchroniseDocumentsByDomainId } from "../api/synchroniseDocuments";
import mapFormValues from "../utils/mapFormValues";

export default function DomainForm({ domain = null, deleteDomain = noop }) {
  const [updateDomain, { error: errorUpdate }] = useCreateOrUpdateDomain();
  const [isEditing, setIsEditing] = React.useState(false);
  const [isActivating, setIsActivating] = React.useState(false);
  const readOnly = !!domain && !isEditing;
  const currentDomainId = useDomain();
  const [isSynchronizing, setIsSynchronizing] = React.useState(false);
  const [
    synchroniseDocumentsByDomainId,
  ] = useQuerySynchroniseDocumentsByDomainId();

  return (
    <div>
      <Formik
        initialValues={{
          subDomainName: get(domain, "subDomainName", ""),
          useExternalDocumentManagement: get(
            domain,
            "useExternalDocumentManagement",
            false
          ),
          documentSoftwareLink: get(domain, "documentSoftwareLink", ""),
          externalDocumentLink: get(domain, "externalDocumentLink", ""),
          organizationDocumentsLink: get(
            domain,
            "organizationDocumentsLink",
            ""
          ),
          useExternalLeaveFund: get(domain, "useExternalLeaveFund", false),
          externalLeaveFundLogin: get(domain, "externalLeaveFundLogin", ""),
          campaignNumber: get(domain, "campaignNumber", ""),
          externalLeaveFundPassword: get(
            domain,
            "externalLeaveFundPassword",
            ""
          ),
          myLinksUrl: get(domain, "myLinksUrl", ""),
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          subDomainName: Yup.string().required("Requis"),
        })}
        onSubmit={async (values, actions) => {
          try {
            await updateDomain({
              id: get(domain, "id"),
              data: mapFormValues(values),
            });
            toast.success("Le domaine a bien été créé");
            actions.resetForm();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values, setSubmitting, resetForm }) => {
          return (
            <Form>
              <div className="mb-4">
                <Input
                  readOnly={readOnly}
                  type="text"
                  name="subDomainName"
                  label="Nom du domaine"
                />
                <Checkbox
                  readOnly={readOnly}
                  type="checkbox"
                  name="useExternalDocumentManagement"
                  label="Fonctionne avec la GED"
                />
                {values.useExternalDocumentManagement && (
                  <>
                    <Input
                      readOnly={readOnly}
                      type="text"
                      name="documentSoftwareLink"
                      label="Accès à la GED"
                    />
                    <Input
                      readOnly={readOnly}
                      type="text"
                      name="externalDocumentLink"
                      label="Lien vers la GED pour les documents des collaborateurs"
                    />
                    <Input
                      readOnly={readOnly}
                      type="text"
                      name="organizationDocumentsLink"
                      label="Lien vers la GED pour les documents d'entreprise"
                    />
                  </>
                )}
              </div>
              <Checkbox
                readOnly={readOnly}
                type="checkbox"
                name="useExternalLeaveFund"
                label="Fonctionne avec la caisse de congés"
              />
              {values.useExternalLeaveFund && (
                <div className="grid grid-cols-2 xl:grid-cols-4 gap-8">
                  <Input
                    readOnly={readOnly}
                    type="text"
                    name="externalLeaveFundLogin"
                    label="Identifiant"
                  />
                  <Input
                    readOnly={readOnly}
                    type="text"
                    name="externalLeaveFundPassword"
                    label="Mot de passe"
                  />
                    <Input
                        readOnly={readOnly}
                        type="number"
                        name="campaignNumber"
                        label="Numéro de campagne"
                    />
                </div>
              )}
              {errorUpdate ? (
                <Alert
                  canBeHidden
                  type="error"
                  message={errorUpdate.title}
                  details={errorUpdate.description}
                />
              ) : null}
              {domain ? (
                <div className="flex items-center">
                  <EditButton
                    onDelete={
                      currentDomainId === domain.id
                        ? false
                        : async () => {
                            setIsActivating(true);
                            await updateDomain({
                              id: domain.id,
                              data: { active: !get(domain, "active", false) },
                            });
                            setIsActivating(false);
                            toast.success("Mise à jour effectuée avec succès");
                          }
                    }
                    labelDelete={
                      get(domain, "active", false) ? "Désactiver" : "Réactiver"
                    }
                    isSubmitting={isSubmitting || isActivating}
                    isEditing={isEditing}
                    onCancel={() => {
                      resetForm();
                      setIsEditing(!isEditing);
                    }}
                    onClick={() => {
                      setIsEditing(!isEditing);
                    }}
                  />
                  {currentDomainId !== domain.id &&
                    !get(domain, "active", false) && (
                      <Button
                        className="ml-4 btn btn--sm bg-red-700 hover:bg-red-800"
                        type="button"
                        isSubmitting={false}
                        onClick={() => {
                          deleteDomain(domain);
                        }}
                      >
                        Supprimer
                      </Button>
                    )}
                  {get(domain, "useExternalDocumentManagement", false) &&
                    !!get(domain, "externalDocumentLink", false) && (
                      <Button
                        className="ml-4 btn btn--sm ml-auto btn--outline--reversed"
                        type="button"
                        isSubmitting={isSynchronizing}
                        onClick={async () => {
                          setIsSynchronizing(true);
                          const {
                            documents_synchronized,
                            organization_documents_synchronized,
                          } = await synchroniseDocumentsByDomainId({
                            domainId: domain.id,
                          });
                          setIsSynchronizing(false);
                          toast.success(
                            `${documents_synchronized} documents synchronisés sur le domaine "${domain.subDomainName}"`
                          );
                          if (organization_documents_synchronized) {
                            toast.success(
                              `${organization_documents_synchronized} documents entreprise synchronisés sur le domaine "${domain.subDomainName}"`
                            );
                          }
                        }}
                      >
                        Synchroniser
                      </Button>
                    )}
                </div>
              ) : (
                <div className="text-center">
                  <Button
                    className={`btn mt-6 inline-block`}
                    isSubmitting={isSubmitting}
                    isForm={true}
                    type="submit"
                    textLabel="Créer"
                  />
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
