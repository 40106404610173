import React, { useState } from "react";
import { map } from "lodash-es";
import Block from "../Block";
import { Link } from "react-router-dom";
import { usePermissionsChecker } from "../../contexts/permissions";
import {useDeleteProcess, useDuplicateProcess} from "../../api/process";
import { ReactComponent as TrashIcon } from "../../svgs/trash.svg";
import { ReactComponent as PenIcon } from "../../svgs/pen.svg";
import { ReactComponent as DuplicateIcon } from "../../svgs/docs-entreprise.svg";
import Modal from "../Modal";
import ProcessForm from "../../forms/ProcessForm";
import { toast } from "react-toastify";

export default function ProcessList({ process }) {
  return (
    <>
      <div className="mt-8">
        {map(process, (p) => (
          <ProcessLine key={p.id} process={p} />
        ))}
      </div>
    </>
  );
}

function ProcessLine({ process: p }) {
  const [deleteProcess] = useDeleteProcess();
  const [duplicateProcess] = useDuplicateProcess();
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const canDelete = usePermissionsChecker({
    permissions: [
      "kdix.actions.process_in_out.edit",
      "kdix.actions.process_in_out.edit.agency",
      "kdix.actions.process_in_out.edit.department",
      "kdix.actions.process_in_out.edit.service",
      "kdix.actions.process_in_out.edit.own",
    ],
  });
  return (
    <>
      <Link className={"cursor-pointer"} to={`/processus/${p.id}`}>
        <Block className={"flex"}>
          <div>{p.label}</div>
          <div className={"ml-auto mr-4 flex gap-4 items-center"}>
            <button
              className="flex justify-center items-center focus:outline-none hover:text-orange-500 text-orange-400"
              onClick={async (e) => {
                e.stopPropagation();
                e.preventDefault();
                setModalEditOpen(true);
              }}
            >
              <PenIcon className="w-5 h-5 fill-current " />
            </button>
            <button
                type="button"
                onClick={async (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    const res = window.confirm("Dupliquer ce processus ?");
                    if (res) {
                        try {
                            await duplicateProcess(p.id);
                        } catch (error) {
                            console.warn(error);
                            toast.error("Erreur lors de la duplication.");
                        }
                    }
                }}
            >
                <DuplicateIcon className="w-6 h-6 fill-current text-orange-400 hover:text-orange-500" />
            </button>
            {canDelete ? (
              <button
                type="button"
                onClick={async (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  const res = window.confirm("La suppression sera définitive");
                  if (res) {
                    try {
                      await deleteProcess(p.id);
                    } catch (error) {
                      console.warn(error);
                      toast.error("Suppression impossible : un processus de ce type est en cours.");
                    }
                  }
                }}
              >
                <TrashIcon className="w-6 h-6 fill-current text-red-500 hover:text-red-600" />
              </button>
            ) : null}
          </div>

          <div className={`${canDelete ? "" : "ml-auto"}`}>
            <svg
              className={`transform fill-current text-green-600 -rotate-90`}
              width="20"
              height="12"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginTop: "5px" }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.667 0L10 7.46 2.333 0 0 2.27 10 12l10-9.73L17.667 0z"
              />
            </svg>
          </div>
        </Block>
      </Link>
      <Modal
        title="Modifier le processus"
        handleClose={(e) => {
          e.stopPropagation();
        }}
        isOpen={modalEditOpen}
        onRequestClose={() => setModalEditOpen(false)}
      >
        <div className="bg-white">
          <ProcessForm
            process={p}
            afterSubmit={() => {
              setModalEditOpen(false);
            }}
          />
        </div>
      </Modal>
    </>
  );
}
