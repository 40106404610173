import React from "react";
import { useUpdateDisplayedAlert } from "../../api/displayedAlerts";
import { flatMap, get, map, noop, size } from "lodash-es";
import {
  useReadByUserMessage,
  useUpdateMessage,
  useQueryInfiniteMessagesReceived,
  useQueryInfiniteMessages,
} from "../../api/messages";
import Avatar from "../Avatar";
import MessageForm from "../../forms/MessageForm";
import Spinner from "../Spinner";
import Alert from "../Alert";
import InfiniteScroll from "react-infinite-scroller";
import Block from "../Block";
import { useToggle } from "react-use";
import { getFirstnameLastname } from "../../utils/names";
import {useDesktop} from "../../hooks/useDesktop";
import {ButtonMessage, SingleMessage} from "../../pages/Collaborator/Messages";

function MessageItemDetail({ message, isPersonal }) {
  return (
    <div className={`p-4 bg-white`}>
      <div className={` mx-4`}>
        <React.Suspense fallback={<Spinner />}>
          <MessageForm
            message={message}
            onSuccess={() => {}}
            isDisabled={isPersonal}
            isInformation={message.information}
          />
        </React.Suspense>
      </div>
    </div>
  );
}

function MessageItem({
  message,
  userIri,
  updateHook,
  readColumn,
  toValidate,
  isPersonal,
}) {
  let consulted = map(get(message, readColumn), "@id");
  const alreadyRead = consulted.includes(userIri);
  const [on, toggle] = useToggle(toValidate);

  return (
    <Block className={"cursor-pointer"}>
      <div
        className={`flex`}
        onClick={() => {
          if (!alreadyRead) {
            consulted.push(userIri);
            updateHook({
              id: get(message, "id"),
              data: { [readColumn]: consulted },
            });
          }
          toggle();
        }}
      >
        <div className={`flex items-center gap-3`}>
          <div className="w-12 h-12">
            <Avatar avatar={message.author.collaborator?.avatar?.contentUrl} />
          </div>
          <div className={`flex flex-col`}>
            <div className={`flex items-center gap-3`}>
              <div className="font-bold">
                {message.author.collaborator
                  ? getFirstnameLastname(message, "author.collaborator")
                  : get(message, "author.username")}
              </div>
              <div>{get(message, "type.label")}</div>
            </div>
            <div>
              {get(message, "createdAt")
                ? new Date(get(message, "createdAt")).toLocaleDateString()
                : ""}
            </div>
          </div>
        </div>
        <div className={"ml-auto mr-4 text-white w-48 "}>
          <div className={"flex flex-wrap"}>
            {alreadyRead || toValidate ? null : (
              <div className={"bg-red-600 px-2 py-1 mb-1 mr-1 rounded"}>
                Non lu
              </div>
            )}
          </div>
        </div>
        <div className={"lg:w-1/6 flex flex-col items-end"}>
          <div className={"text-right"}>
            <svg
              className={`transform fill-current text-green-600 ${
                on ? "rotate-180" : ""
              }`}
              width="20"
              height="12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.667 0L10 7.46 2.333 0 0 2.27 10 12l10-9.73L17.667 0z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className={`${on ? "block" : "hidden"} mt-2 cursor-default`}>
        <MessageItemDetail
          toValidate={toValidate}
          isPersonal={isPersonal}
          message={message}
        />
      </div>
    </Block>
  );
}

function MessagesListContainer({
  data: { data, fetchMore, canFetchMore },
  userIri,
  isPersonal,
  toValidate,
  readColumn,
}) {
  const messages = flatMap(data, (page) => page["hydra:member"]);
  const [updateMessageRead] = useReadByUserMessage();
  const [updateMessage] = useUpdateMessage();

  if (size(messages) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="Aucun message" />
      </div>
    );
  }

  return (
    <div>
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {map(messages, (message) => {
          return (
            <MessageItem
              message={message}
              key={message["id"]}
              archiveHook={useUpdateDisplayedAlert}
              setItem={noop()}
              userIri={userIri}
              updateHook={(e) => {
                isPersonal ? updateMessageRead(e.id) : updateMessage(e);
              }}
              toValidate={toValidate}
              readColumn={readColumn}
              isPersonal={isPersonal}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
}

function MessagesListBlock({ userId, userIri, isPersonal = false }) {
  return isPersonal ? (
    <MessagesPersonal userIri={userIri} userId={userId} />
  ) : (
    <MessagesToValidate userIri={userIri} />
  );
}

function MessagesPersonal({ userId, userIri }) {
  //Attention à bien verrouiller l'heure pour éviter des changements de filtres avec l'infinite scroll
  const dt = new Date();
  dt.setHours(0, 0, 0, 0);
  dt.setDate(dt.getDate() - 6);

  const isDesktop = useDesktop();
  const [messageList, setMessageList] = React.useState("received");

  const { data: dataReceived, fetchMore: fetchMoreReceived, canFetchMore: canFetchMoreReceived } = useQueryInfiniteMessagesReceived({
    approved: true,
    sent: true,
    "order[createdAt]": "desc",
    "dateApproved[after]": dt,
    user_id: userId,
  });

  const { data: dataSend, fetchMore: fetchMoreSend, canFetchMore: canFetchMoreSend } = useQueryInfiniteMessages({
    "exists[approved]": "false",
    "order[createdAt]": "desc",
    author: userId,
  });

  const messagesReceived = flatMap(dataReceived, (page) => page["hydra:member"]);
  const messagesSend = flatMap(dataSend, (page) => page["hydra:member"]);
  const [updateMessageRead] = useReadByUserMessage();

  return (
      <>
        {isDesktop ? (
            <div className="grid grid-cols-2 py-6">
              <div className="text-lg text-center uppercase">
                Reçus
              </div>
              <div className="text-lg text-center uppercase">
                Envoyés (En attente de validation)
              </div>
            </div>
        ) : (
            <div className="py-3 flex justify-center gap-3">
              <ButtonMessage
                  active={messageList === "received"}
                  onClick={() => {
                    setMessageList("received");
                  }}
              >
                Reçus
              </ButtonMessage>
              <ButtonMessage
                  active={messageList === "sent"}
                  onClick={() => {
                    setMessageList("sent");
                  }}
              >
                Envoyés
              </ButtonMessage>
            </div>
        )}

        <div className="xl:grid xl:grid-cols-2 xl:gap-5">
          <div className={!isDesktop && messageList === "sent" ? "hidden" : ""}>
            <React.Suspense fallback={<Spinner />}>
              <InfiniteScroll
                  pageStart={1}
                  initialLoad={false}
                  loadMore={() => {
                    fetchMoreReceived();
                  }}
                  hasMore={canFetchMoreReceived !== false}
                  loader={
                    <div key={0} className=" relative">
                      <Spinner />
                    </div>
                  }
              >
                {map(messagesReceived, (message, index) => {
                  const readColumn = 'readBy';
                  let consulted = map(get(message, readColumn), "@id");
                  const alreadyRead = consulted.includes(userIri);

                  return (
                      <SingleMessage
                          key={index}
                          message={message}
                          send={false}
                          isDesktop={isDesktop}
                          isCopy={false}
                          handleClick={() => {
                            if (!alreadyRead) {
                              consulted.push(userIri);
                              updateMessageRead(
                                  message.id,
                                  {
                                    id: get(message, "id"),
                                    data: { [readColumn]: consulted },
                                  });
                            }
                          }}
                      />
                  )
                })}
              </InfiniteScroll>
            </React.Suspense>
          </div>
          <div
              className={!isDesktop && messageList === "received" ? "hidden" : ""}
          >
            <React.Suspense fallback={<Spinner />}>
              <InfiniteScroll
                  pageStart={1}
                  initialLoad={false}
                  loadMore={() => {
                    fetchMoreSend();
                  }}
                  hasMore={canFetchMoreSend !== false}
                  loader={
                    <div key={0} className=" relative">
                      <Spinner />
                    </div>
                  }
              >
                {map(messagesSend, (message, index) => (
                    <SingleMessage
                        key={index}
                        message={message}
                        send={true}
                        isDesktop={isDesktop}
                        isValidated={false}
                        canBeModified={true}
                    />
                ))}
              </InfiniteScroll>
            </React.Suspense>
          </div>
        </div>

      </>
  );

  //return (
  //  <MessagesListContainer
  //    userIri={userIri}
  //    data={dataReceived}
  //    toValidate={false}
  //    readColumn={"readBy"}
  //    isPersonal={true}
  //  />
  //);
}

function MessagesToValidate({ userIri }) {
  const data = useQueryInfiniteMessages({
    "exists[approved]": "false",
    "order[createdAt]": "desc",
  });
  return (
    <MessagesListContainer
      userIri={userIri}
      data={data}
      toValidate={true}
      readColumn={"consultedBy"}
      isPersonal={false}
    />
  );
}

export default MessagesListBlock;
