import {
  create,
  deleteById,
  find,
  findById,
  updateById,
  updateOrCreate,
} from "./index";
import {
  queryCache,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "events";
//

const mutateDefaultOptions = {
  throwOnError: true,
  // When mutate is called:
  onMutate: (newEvent = null) => {
    queryCache.cancelQueries(ressourceKey);
  },
  onSuccess: () => {
    queryCache.refetchQueries(ressourceKey);
    queryCache.refetchQueries("history");
  },
};

export function useQueryEventById(id) {
  return useQuery(id && [ressourceKey, id], findById);
}

export function useQueryEvents(filters) {
  return useInfiniteQuery([ressourceKey, filters], find, {
    getFetchMore: (lastGroup) => {
      // extract next page int from IRI
      const nextUrl = lastGroup["hydra:view"]["hydra:next"];
      const match = /page=(\d+)/.exec(nextUrl);
      if (match) {
        return match[1];
      }

      return false;
    },
  });
}

export function useCreateEvent() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: (newEvent = null) => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries("history");
    },
  });
}

export function useUpdateEvent() {
  return useMutation(updateById.bind(null, ressourceKey), mutateDefaultOptions);
}

export function useCreateOrUpdateEvent() {
  return useMutation(
    updateOrCreate.bind(null, ressourceKey),
    mutateDefaultOptions
  );
}

export function useDeleteEvent(resetHistory = false) {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
      if (resetHistory) {
        queryCache.refetchQueries("history");
      }
    },
  });
}
