import {
  queryCache,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";
import queryWithToken, {
  create,
  deleteById,
  find,
  findById,
  findExport,
  updateById,
} from "./index";
import fileDownload from "js-file-download";
import { get, reduce } from "lodash-es";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "vehicles";
//

export function useQueryVehicleById(id) {
  return useQuery(id && [ressourceKey, id], findById, {
    refetchOnWindowFocus: false,
  });
}

export function useQueryVehicles(filters) {
  return useInfiniteQuery([ressourceKey, filters], find, {
    getFetchMore: (lastGroup) => {
      const nextUrl = lastGroup["hydra:view"]["hydra:next"];
      const match = /page=(\d+)/.exec(nextUrl);
      if (match) {
        return match[1];
      }

      return false;
    },
  });
}

export function useQueryVehiclesListAll(filters) {
  const { data, ...rest } = useQuery(
      [ressourceKey, filters],
      () =>
          queryWithToken(`${process.env.REACT_APP_API_URL}/vehicles`, {
            method: "GET",
            params: {
              page: null,
              pagination: false,
              ...filters,
            },
          }),
      {
        refetchOnWindowFocus: false,
      }
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useCreateVehicle() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useUpdateVehicle() {
  return useMutation(updateById.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: (newVehicle = null) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries([ressourceKey, data.id]);
    },
  });
}

const findVehicleHistory = (_, id) => {
  return queryWithToken(
    `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/history`,
    {
      method: "GET",
    }
  );
};

export function useGetVehicleHistory(id) {
  const { data, ...rest } = useQuery(
    id && ["history", id],
    findVehicleHistory,
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    history: get(data, "historyEvents.hydra:member"),
    ...rest,
  };
}

export function useGetVehicleFolders(id) {
  const { data, isFetching } = useQuery(
    id && ["vehicle_folders", id],
    () => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/documents`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: reduce(
      get(data, "hydra:member", []),
      (obj, next) => {
        return {
          ...obj,
          [next["@id"]]: next,
        };
      },
      {}
    ),
    isFetching,
  };
}

export function useGetNextAndPrevVehicle(id) {
  const { data, ...rest } = useQuery(
    id && ["next_prev_collaborator", id],
    () => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/prev_next`,
        {
          method: "GET",
        }
      );
    }
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useDeleteVehicle() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export async function downloadVehicleExport(filters) {
  const data = await findExport(ressourceKey, filters);
  fileDownload(data, "export_vehicules.csv");
}
